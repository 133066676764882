<bp-landing-dialog-title dialogTitle="Two-factor Authentication"></bp-landing-dialog-title>
<div class="two-fa-login-dialog-icon">
  <img
    *ngIf="twoFaType === 'google2fa'"
    class="two-fa-login-dialog-google2fa-icon"
    src="/assets/landing-new/dialogs/mobile-check.svg"
    alt="Mobile authenticator icon"
  />
  <img
    *ngIf="twoFaType === 'email2fa'"
    class="two-fa-login-dialog-email-icon"
    src="/assets/landing-new/dialogs/email.svg"
    alt="Email 2FA icon"
  />
</div>
<bp-two-factor-auth-for-login
  [twoFaType]="twoFaType"
  [errorMessage]="twoFAErrorMessage"
  [errorShown]="twoFAErrorShown"
  (fill)="tokenFilled.emit($event)"
  [isInputDisabled]="isInputDisabled"
></bp-two-factor-auth-for-login>
<p class="landing-dialog-info-message two-fa-login-dialog-message" *ngIf="twoFaType === 'google2fa'">
  Lost your device? Want to request a two-factor authentication reset? Contact
  <a [href]="'mailto:' + supportEmail" class="landing-dialog-link">{{ supportEmail }}.</a>
</p>
<p class="landing-dialog-info-message two-fa-login-dialog-message" *ngIf="twoFaType === 'email2fa'">
  Email with confirmation code was sent to your email address. In case of problems contact
  <a [href]="'mailto:' + supportEmail" class="landing-dialog-link">{{ supportEmail }}.</a>
</p>
