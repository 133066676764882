<form [formGroup]="twoFAForm">
  <div *ngIf="isGoogle2fa" class="two-fa-auth-text">
    Please insert two factor code displayed in your Google Authenticator app.
  </div>
  <div *ngIf="!isGoogle2fa" class="two-fa-auth-text">
    Please insert e-mail code we just sent you to your e-mail address.
  </div>
  <div class="two-fa-auth-inputs">
    <ng-container *ngFor="let symbol of code.controls; index as idx">
      <div class="two-fa-auth-input-wrapper">
        <input
          class="two-fa-auth-input"
          [id]="inputIdPrefix + idx"
          [formControl]="convertAbstractToFormControl(symbol)"
          (keydown)="onKeyDown($event, idx)"
          (keyup)="onKeyUp()"
          (input)="onInput($event, idx)"
          (focus)="this.code.at(idx).setValue('')"
          [type]="controlType"
          (paste)="onPaste($event, idx)"
        />
        <span class="two-fa-auth-input-underline"></span>
      </div>
    </ng-container>
  </div>
  <div class="two-fa-auth-error" *ngIf="errorShown">{{ errorMessage }}</div>
</form>
