import { Directive, EventEmitter, HostListener, Output } from '@angular/core';

@Directive({
  selector: '[bpDragAndDropUpload]',
  standalone: true,
})
export class DragAndDropUploadDirective {
  @Output() dragOver = new EventEmitter();
  @Output() uploadedFiles = new EventEmitter();

  @HostListener('dragover', ['$event'])
  onDragOver(event: any): void {
    event.preventDefault();
    event.stopPropagation();

    this.dragOver.emit(true);
  }

  @HostListener('dragleave', ['$event'])
  onDragLeave(event: any): void {
    event.preventDefault();
    event.stopPropagation();

    this.dragOver.emit(false);
  }

  @HostListener('drop', ['$event'])
  onDragDrop(event: any): void {
    event.preventDefault();
    event.stopPropagation();

    this.dragOver.emit(false);
    if (!event.dataTransfer.files || event.dataTransfer.files.length < 1) {
      return;
    }

    this.uploadedFiles.emit(event.dataTransfer.files);
  }
}
