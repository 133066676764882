import { createReducer, on } from '@ngrx/store';
import {
  getDotfileCaseAsMerchantAction,
  getDotfileCaseAsMerchantErrorAction,
  getDotfileCaseAsMerchantSuccessAction,
  getDotfileClientPortalLinkAction,
  getDotfileClientPortalLinkErrorAction,
  getDotfileClientPortalLinkSuccessAction,
  getMerchantDotfileCaseAsAdminAction,
  getMerchantDotfileCaseAsAdminErrorAction,
  getMerchantDotfileCaseAsAdminSuccessAction,
  getVerificationBasicInfoAsMerchantAction,
  getVerificationBasicInfoAsMerchantErrorAction,
  getVerificationBasicInfoAsMerchantSuccessAction,
  postVerificationBasicInfoAsMerchantAction,
  postVerificationBasicInfoAsMerchantErrorAction,
  postVerificationBasicInfoAsMerchantSuccessAction,
} from '../actions/merchant-verification.actions';
import { DotfileCaseAdminTo, DotfileCaseTo } from '../models/api/dotfile-case.model';
import { ErrorModel } from '../models/api/error.model';
import { BasicInfoUnionModel } from '../models/api/merchant-verification/basic-info-union.model';
import { StateModel } from '../models/auxiliary/state.model';

export type MerchantVerificationAsAdminState = StateModel<DotfileCaseAdminTo>;
export type MerchantVerificationAsMerchantState = {
  errors: ErrorModel[] | null;
  dotfileCase: DotfileCaseTo | null;
  dotfileClientPortalLink: { link: string; expires: string } | null;
  basicInfo: BasicInfoUnionModel | null;
  isLoadingCase: boolean;
  isLoadingLink: boolean;
  isLoadingBasicInfo: boolean;
};

const INITIAL_STATE_ADMIN: MerchantVerificationAsAdminState = {
  data: null,
  errors: null,
  loading: false,
};

const INITIAL_STATE_MERCHANT: MerchantVerificationAsMerchantState = {
  errors: null,
  dotfileCase: null,
  dotfileClientPortalLink: null,
  basicInfo: null,
  isLoadingCase: false,
  isLoadingLink: false,
  isLoadingBasicInfo: false,
};

export const merchantVerificationAsAdminReducer = createReducer(
  INITIAL_STATE_ADMIN,
  on(getMerchantDotfileCaseAsAdminAction, () => ({
    ...INITIAL_STATE_ADMIN,
    loading: true,
  })),
  on(getMerchantDotfileCaseAsAdminSuccessAction, (state, { dotfileCase }) => ({
    ...state,
    data: dotfileCase,
    loading: false,
  })),
  on(getMerchantDotfileCaseAsAdminErrorAction, (state, { errors }) => ({
    ...state,
    errors: errors,
    loading: false,
  })),
);

export const merchantVerificationAsMerchantReducer = createReducer(
  INITIAL_STATE_MERCHANT,
  on(getDotfileCaseAsMerchantAction, (state) => ({
    ...state,
    isLoadingCase: true,
  })),
  on(getDotfileCaseAsMerchantSuccessAction, (state, { dotfileCase }) => ({
    ...state,
    dotfileCase,
    isLoadingCase: false,
  })),
  on(getDotfileCaseAsMerchantErrorAction, (state, { errors }) => ({
    ...state,
    errors,
    isLoadingCase: false,
  })),
  on(getVerificationBasicInfoAsMerchantAction, (state) => ({
    ...state,
    isLoadingBasicInfo: true,
  })),
  on(getVerificationBasicInfoAsMerchantSuccessAction, (state, { basicInfo }) => ({
    ...state,
    basicInfo,
    isLoadingBasicInfo: false,
  })),
  on(getVerificationBasicInfoAsMerchantErrorAction, (state, { errors }) => ({
    ...state,
    errors,
    isLoadingBasicInfo: false,
  })),
  on(postVerificationBasicInfoAsMerchantAction, (state) => ({
    ...state,
    isLoadingBasicInfo: true,
  })),
  on(postVerificationBasicInfoAsMerchantSuccessAction, (state, { basicInfo }) => ({
    ...state,
    basicInfo,
    isLoadingBasicInfo: false,
  })),
  on(postVerificationBasicInfoAsMerchantErrorAction, (state, { errors }) => ({
    ...state,
    basicInfo: null,
    errors,
    isLoadingBasicInfo: false,
  })),
  on(getDotfileClientPortalLinkAction, (state) => ({
    ...state,
    isLoadingLink: true,
  })),
  on(getDotfileClientPortalLinkSuccessAction, (state, dotfileClientPortalLink) => ({
    ...state,
    dotfileClientPortalLink,
    isLoadingLink: false,
  })),
  on(getDotfileClientPortalLinkErrorAction, (state, { errors }) => ({
    ...state,
    errors,
    isLoadingLink: false,
  })),
);
