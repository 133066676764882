import { NgClass, NgIf, NgTemplateOutlet } from '@angular/common';
import { Component, Inject } from '@angular/core';
import {
  FormsModule,
  ReactiveFormsModule,
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Actions, ofType } from '@ngrx/effects';
import { Store, select } from '@ngrx/store';
import { CountryCode, getCountryCallingCode, isSupportedCountry } from 'libphonenumber-js';
import moment, { Moment } from 'moment';
import { takeUntil } from 'rxjs/operators';
import {
  createMerchantPersonAdminAction,
  createMerchantPersonAdminSuccessAction,
} from '../../../../shared/actions/merchant-person.actions';
import { AbstractComponent } from '../../../../shared/components/abstract.component';
import { CloseReason, DialogResult } from '../../../../shared/components/dialogs/dialog-result';
import { HeaderDialogComponent } from '../../../../shared/components/dialogs/header-dialog/header-dialog.component';
import { PlatformCheckboxComponent } from '../../../../shared/components/platform-checkbox/platform-checkbox.component';
import { CountrySelectComponent } from '../../../../shared/components/select/country-select/country-select.component';
import { closeDialogWithAnimation } from '../../../../shared/dialogs-utils';
import { PersonType } from '../../../../shared/enums/person-type.enum';
import { CountryTo } from '../../../../shared/models/api/country.model';
import { PersonModel } from '../../../../shared/models/api/merchant-profile/person-model';
import { AppStateModel } from '../../../../shared/models/auxiliary/app-state.model';
import { MomentDatePipe } from '../../../../shared/pipes/moment-date.pipe';
import { selectIsAdmin } from '../../../../shared/selectors/user.selector';
import { MessageService } from '../../../../shared/services/message.service';
import { CountryService } from '../../../../shared/store/countries/country-list.service';
import {
  createMerchantDirectorAction,
  createMerchantDirectorErrorAction,
  createMerchantDirectorSuccessAction,
  updateMerchantDirectorAction,
  updateMerchantDirectorSuccessAction,
} from '../../../../shared/store/merchant-directors/merchant-directors.actions';
import { getIsoDateString, markAllAsTouched } from '../../../../shared/utils';
import { maxDateValidator } from '../../../../shared/validators/max-date.validator';
import { whitespaceTrimValidator } from '../../../../shared/validators/whitespace-trim.validator';
import { normalizePhoneNumber } from '../../../shared/utils';
import { phoneNumberValidator } from '../../../shared/validators/phone-number.validator';

@Component({
  selector: 'bp-add-or-edit-merchant-director-dialog',
  templateUrl: './add-or-edit-merchant-director-dialog.component.html',
  styleUrls: ['./add-or-edit-merchant-director-dialog.component.scss'],
  standalone: true,
  imports: [
    HeaderDialogComponent,
    FormsModule,
    ReactiveFormsModule,
    NgTemplateOutlet,
    PlatformCheckboxComponent,
    NgClass,
    NgIf,
    CountrySelectComponent,
    MomentDatePipe,
  ],
})
export class AddOrEditMerchantDirectorDialogComponent extends AbstractComponent {
  merchantDirector?: PersonModel;
  merchantDirectorForm: UntypedFormGroup;
  countries: CountryTo[] = [];
  minimalBirthDate: Moment;
  isAdmin = false;
  selectedNationality?: CountryTo;
  selectedCountryCode?: CountryTo;
  createdRequest = false;

  constructor(
    private store: Store<AppStateModel>,
    private actions: Actions,
    private fb: UntypedFormBuilder,
    private messageService: MessageService,
    private countryService: CountryService,
    private dialogRef: MatDialogRef<AddOrEditMerchantDirectorDialogComponent>,
    @Inject(MAT_DIALOG_DATA) private data: PersonModel,
  ) {
    super();
    this.store
      .pipe(select(selectIsAdmin), takeUntil(this.unsubscribe$))
      .subscribe((isAdmin) => (this.isAdmin = isAdmin));

    this.minimalBirthDate = moment().add(-15, 'years');

    this.merchantDirectorForm = this.fb.group({
      firstName: ['', [Validators.maxLength(100), Validators.required, whitespaceTrimValidator]],
      lastName: ['', [Validators.maxLength(100), Validators.required, whitespaceTrimValidator]],
      birthDate: ['', [Validators.required, maxDateValidator(this.minimalBirthDate)]],
      birthPlace: ['', [Validators.maxLength(100), Validators.required, whitespaceTrimValidator]],
      nationalityCountryCode: ['', [Validators.required]],

      street: ['', [Validators.maxLength(100), Validators.required, whitespaceTrimValidator]],
      city: ['', [Validators.maxLength(100), Validators.required, whitespaceTrimValidator]],
      zip: ['', [Validators.maxLength(10), Validators.required, whitespaceTrimValidator]],
      countryCode: ['', [Validators.required]],
      phone: ['', [Validators.required, phoneNumberValidator, whitespaceTrimValidator]],
      flags: this.fb.group({
        pep: [''],
        ubo: [''],
        us: [''],
      }),
    });

    this.actions
      .pipe(ofType(createMerchantDirectorSuccessAction), takeUntil(this.unsubscribe$))
      .subscribe(() => this.showSuccessMessageAndClose('Director was added'));

    this.actions
      .pipe(ofType(createMerchantDirectorErrorAction), takeUntil(this.unsubscribe$))
      .subscribe(({ errors }) => {
        this.createdRequest = false;
        this.messageService.showErrors(errors, 'Add Director Error');
      });

    this.actions
      .pipe(ofType(updateMerchantDirectorSuccessAction), takeUntil(this.unsubscribe$))
      .subscribe(() => this.showSuccessMessageAndClose('Director was updated'));

    this.actions
      .pipe(ofType(createMerchantPersonAdminSuccessAction), takeUntil(this.unsubscribe$))
      .subscribe(() => this.showSuccessMessageAndClose('Director was created'));

    this.countryService
      .getCountries()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((countries) => {
        this.countries = countries;
        if (data) {
          this.selectedNationality = this.findCountryByCode(data.nationalityCountryCode);
          this.selectedCountryCode = this.findCountryByCode(data.countryCode);
        }
      });

    if (this.data) {
      this.loadMerchantDirectorValues(this.data);
    }

    this.subscribeToCountryCode();
  }

  private showSuccessMessageAndClose(message: string): void {
    this.messageService.success(message);
    closeDialogWithAnimation(this.dialogRef, new DialogResult(CloseReason.SUCCESS));
  }

  private findCountryByCode(countryCode: string): CountryTo | undefined {
    return this.countries.find((country) => country.alpha2 === countryCode);
  }

  protected loadMerchantDirectorValues(merchantDirector: PersonModel): void {
    this.merchantDirector = merchantDirector;
    this.merchantDirectorForm.reset();
    // prevents setting of actual date, when the dialog is opened by admin with non-null data
    if (merchantDirector.birthDate) {
      this.merchantDirectorForm.patchValue({
        ...merchantDirector,
        birthDate: getIsoDateString(moment(merchantDirector.birthDate)),
      });
    }
  }

  isEditForm(): boolean {
    return this.merchantDirector != null && this.merchantDirector.id != null;
  }

  submitForm(): void {
    if (this.merchantDirectorForm.invalid) {
      markAllAsTouched(this.merchantDirectorForm);
      return;
    }

    if (this.isEditForm()) {
      this.updateMerchantDirector();
    } else {
      this.addMerchantDirector();
    }
  }

  addMerchantDirector(): void {
    this.patchPhoneNumberBeforeRequest();
    if (this.isAdmin) {
      const payload = {
        ...this.merchantDirectorForm.value,
        id: null,
        personType: PersonType.DIRECTOR,
        merchantId: this.data.merchantHashId,
      };
      payload.birthDate = getIsoDateString(moment(payload.birthDate));
      this.store.dispatch(
        createMerchantPersonAdminAction({
          person: payload,
          merchantId: this.data.merchantHashId,
        }),
      );
    } else {
      if (!this.createdRequest) {
        this.createdRequest = true;
        this.store.dispatch(createMerchantDirectorAction({ merchantDirector: { ...this.merchantDirectorForm.value } }));
      }
    }
  }

  updateMerchantDirector(): void {
    this.patchPhoneNumberBeforeRequest();
    this.store.dispatch(
      updateMerchantDirectorAction({
        merchantDirector: {
          ...this.merchantDirectorForm.value,
          id: this.merchantDirector?.id,
          merchantHashId: this.data.merchantHashId,
        },
        isAdmin: this.isAdmin,
      }),
    );
  }

  isMerchantDirectorFieldMissing(fieldName: string): boolean {
    const fieldControl = this.merchantDirectorForm.get(fieldName);
    return !!fieldControl && fieldControl.touched && !!fieldControl.getError('required');
  }

  isFieldTooLong(fieldName: string): boolean {
    const fieldControl = this.merchantDirectorForm.get(fieldName);
    return fieldControl && fieldControl.touched && fieldControl.getError('maxlength');
  }

  getMaxLengthErrorMessage(fieldName: string): string {
    const maxLength = this.merchantDirectorForm.get(fieldName)?.getError('maxlength');

    if (maxLength == null) {
      return '';
    }

    return `Max length is ${maxLength.requiredLength} (actual is ${maxLength.actualLength})`;
  }

  get birthDate(): UntypedFormControl {
    return this.merchantDirectorForm.get('birthDate') as UntypedFormControl;
  }

  get phoneInvalid(): boolean {
    const phoneControl = this.merchantDirectorForm.get('phone');
    return !!phoneControl?.touched && !!phoneControl.getError('phoneNumber');
  }

  get countryNotSupported(): boolean {
    const countryControl = this.merchantDirectorForm.get('countryCode');
    return !!countryControl?.touched && !!countryControl.getError('countryNotSupported');
  }

  closeForm(): void {
    closeDialogWithAnimation(this.dialogRef, new DialogResult(CloseReason.CANCEL));
  }

  containsWhitespaceAtStartOrEnd(fieldName: string): boolean {
    const control = this.merchantDirectorForm.get(fieldName);
    return !!control?.touched && !!control.getError('whitespaces');
  }

  onSelectChange(country: CountryTo, controlName: string): void {
    this.merchantDirectorForm.get(controlName)?.patchValue(country.alpha2);
  }

  subscribeToCountryCode(): void {
    this.merchantDirectorForm
      .get('countryCode')
      ?.valueChanges.pipe(takeUntil(this.unsubscribe$))
      .subscribe((value: string) => {
        this.setNumberCountryCode(value);
      });
  }

  setNumberCountryCode(countryCode: string): void {
    if (!isSupportedCountry(countryCode)) {
      return;
    }
    const phoneControl = this.merchantDirectorForm.get('phone');
    const callingCode = getCountryCallingCode(<CountryCode>countryCode);
    phoneControl?.setValue('+' + callingCode);
  }

  private patchPhoneNumberBeforeRequest(): void {
    const insertedPhone = this.merchantDirectorForm.get('phone')?.value;
    if (insertedPhone) {
      this.merchantDirectorForm.get('phone')?.patchValue(normalizePhoneNumber(insertedPhone));
    }
  }
}
