import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class EmailRecoveryService {
  private sharedEmail: string;
  setEmail(email: string): void {
    this.sharedEmail = email;
  }
  getEmail(): string {
    return this.sharedEmail;
  }
}
