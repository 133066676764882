import { Overlay, OverlayRef } from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';
import { Component } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { timer } from 'rxjs';
import { filter, mergeMap, takeUntil } from 'rxjs/operators';
import { AppStateModel } from '../../models/auxiliary/app-state.model';
import { selectLoader } from '../../selectors/loader.selectors';
import { AbstractComponent } from '../abstract.component';
import { LoadingOverlayComponent } from './loading-overlay/loading-overlay.component';

@Component({
  selector: 'bp-loading-overlay-container',
  template: '',
  standalone: true,
})
export class LoadingOverlayContainerComponent extends AbstractComponent {
  static DEBOUNCE_TIME = 500; // wait 500ms before showing overlay

  private overlayRef: OverlayRef;

  constructor(
    private store: Store<AppStateModel>,
    private overlay: Overlay,
  ) {
    super();

    const loader$ = this.store.pipe(select(selectLoader));
    const loaderFinished$ = loader$.pipe(filter((state) => state.showLoaderRequests === 0));

    loaderFinished$.pipe(takeUntil(this.unsubscribe$)).subscribe(() => this.hideGlobalOverlay());

    loader$
      .pipe(
        filter((state) => state.showLoaderRequests > 0),
        // Add some debounce time before showing loader
        mergeMap((state) =>
          timer(LoadingOverlayContainerComponent.DEBOUNCE_TIME).pipe(
            // Do not show loader at all, if loading finishes before debounceTime
            takeUntil(loaderFinished$),
          ),
        ),
        takeUntil(this.unsubscribe$),
      )
      .subscribe(() => this.showGlobalOverlay());
  }

  showGlobalOverlay(): void {
    this.overlayRef = this.overlay.create({
      positionStrategy: this.overlay.position().global().centerHorizontally().centerVertically(),
      hasBackdrop: true,
    });

    this.overlayRef.attach(new ComponentPortal(LoadingOverlayComponent));
  }

  hideGlobalOverlay(): void {
    if (this.overlayRef != null) {
      this.overlayRef.dispose();
      this.overlayRef = null;
    }
  }
}
