import { AriaDescriber, FocusMonitor } from '@angular/cdk/a11y';
import { Directionality } from '@angular/cdk/bidi';
import { Overlay, ScrollDispatcher } from '@angular/cdk/overlay';
import { Platform } from '@angular/cdk/platform';
import { AfterViewInit, Directive, ElementRef, Inject, Input, NgZone, Optional, ViewContainerRef } from '@angular/core';
import {
  MAT_TOOLTIP_DEFAULT_OPTIONS,
  MAT_TOOLTIP_SCROLL_STRATEGY,
  MatTooltip,
  MatTooltipDefaultOptions,
} from '@angular/material/tooltip';
import { isElementOverflown } from '../utils';

@Directive({
  selector: '[bpTooltipOnEllipsis]',
  standalone: true,
})
export class TooltipOnEllipsisDirective extends MatTooltip implements AfterViewInit {
  @Input('bpTooltipOnEllipsis')
  get tooltip(): string {
    return this.message;
  }

  set tooltip(value: string) {
    this.message = value;
  }

  constructor(
    private elementRef: ElementRef,
    overlay: Overlay,
    scrollDispatcher: ScrollDispatcher,
    viewContainerRef: ViewContainerRef,
    ngZone: NgZone,
    platform: Platform,
    ariaDescriber: AriaDescriber,
    focusMonitor: FocusMonitor,
    @Inject(MAT_TOOLTIP_SCROLL_STRATEGY) scrollStrategy: any,
    @Optional() dir: Directionality,
    @Optional() @Inject(MAT_TOOLTIP_DEFAULT_OPTIONS) defaultOptions: MatTooltipDefaultOptions,
  ) {
    super(
      overlay,
      elementRef,
      scrollDispatcher,
      viewContainerRef,
      ngZone,
      platform,
      ariaDescriber,
      focusMonitor,
      scrollStrategy,
      dir,
      defaultOptions,
      document,
    );
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      // hide tooltip if text element is not ellipsized
      if (!isElementOverflown(this.elementRef.nativeElement)) {
        this.tooltip = null;
      }
    }, 500);
  }
}
