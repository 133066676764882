import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { AbstractComponent } from '../../../../shared/components/abstract.component';
import { GenericDialogComponent } from '../../../../shared/components/dialogs/generic-dialog/generic-dialog.component';
import { closeDialogWithAnimation } from '../../../../shared/dialogs-utils';

@Component({
  selector: 'bp-aml-wizard-leave-dialog',
  templateUrl: './aml-wizard-leave-dialog.component.html',
  styleUrls: ['./aml-wizard-leave-dialog.component.scss'],
  standalone: true,
  imports: [GenericDialogComponent],
})
export class AmlWizardLeaveDialogComponent extends AbstractComponent {
  constructor(public dialogRef: MatDialogRef<AmlWizardLeaveDialogComponent>) {
    super();
  }

  closeModal(leaveAnyway: boolean): void {
    closeDialogWithAnimation(this.dialogRef, leaveAnyway);
  }
}
