import { createFeatureSelector } from '@ngrx/store';
import { MerchantProfilePageState } from '../reducers/merchant-profile-page.reducer';
import { MerchantProfileState } from '../reducers/merchant-profile.reducer';

/** @deprecated Will be replaced by Dotfile integration CBP-5349 */
export const selectMerchantProfile = createFeatureSelector<MerchantProfileState>('merchantProfile');

export const selectMerchantProfilePage = createFeatureSelector<MerchantProfilePageState>('merchantProfilePage');

export const selectMerchantProfileAdmin = createFeatureSelector<MerchantProfileState>('merchantProfileAdmin');
