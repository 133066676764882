<ng-container *ngIf="twoFADialogShown; else inputFormTemplate">
  <bp-landing-dialog (closePressed)="closeLoginDialog(1)">
    <div class="dialog-two-fa-auth-content animate__appear-down">
      <bp-two-fa-login-dialog
        [twoFaType]="twoFaType"
        [twoFAErrorMessage]="twoFAErrorMessage"
        [twoFAErrorShown]="twoFAErrorShown"
        [isInputDisabled]="twoFaCodeLoginDispatched"
        (tokenFilled)="sendTwoFAToken($event)"
      ></bp-two-fa-login-dialog>
    </div>
  </bp-landing-dialog>
</ng-container>
<ng-template #inputFormTemplate>
  <bp-landing-dialog (closePressed)="closeLoginDialog(1)">
    <div class="dialog-login-content animate__appear-down">
      <bp-landing-dialog-title dialogTitle="Log in"></bp-landing-dialog-title>
      <div class="login-important">Always make sure you are logging in on https://confirmo.net</div>
      <form [formGroup]="loginForm" class="login-dialog-form" (ngSubmit)="login()" novalidate>
        <div class="login-dialog-email-input-wrapper">
          <bp-input-logo
            icon="email"
            formControlName="email"
            type="email"
            autocomplete="email"
            placeholder="E-mail"
            [errorMap]="emailErrorMap"
            (keyup)="setEmail()"
          ></bp-input-logo>
        </div>
        <div class="login-dialog-password-input-wrapper">
          <bp-input-logo
            icon="lock"
            formControlName="password"
            type="password"
            autocomplete="current-password"
            placeholder="Password"
            [errorMap]="passwordErrorMap"
          ></bp-input-logo>
        </div>
        <div class="login-dialog-errors-wrapper">
          <p *ngFor="let error of apiErrors" class="dialog-error dialog-notes">{{ error }}</p>
        </div>
        <bp-landing-dialog-button class="login-dialog-button" type="submit" [disabled]="loginForm.invalid">
          Log in
        </bp-landing-dialog-button>
      </form>
      <p *ngIf="justActivated" class="dialog-notes">Your account has been successfully activated, please log in.</p>
      <p *ngIf="passwordChanged" class="dialog-notes">
        Password recovery was successful, please log in with a new password.
      </p>
      <div class="landing-dialog-info-message login-dialog-footer-text">
        <button type="button" class="landing-dialog-as-link" (click)="openSignupDialog()">Sign up</button>
        to Confirmo
        <br />
        <button type="button" class="landing-dialog-as-link" (click)="openRecoveryDialog()">Reset password</button>
      </div>
    </div>
  </bp-landing-dialog>
</ng-template>
