<bp-landing-dialog (closePressed)="closeDialog()">
  <div class="dialog-password-recovery-content animate__appear-down">
    <ng-container *ngIf="emailSentTo; else recoveryFormRef">
      <bp-landing-dialog-title dialogTitle="E-mail sent" titleImage="send"></bp-landing-dialog-title>
      <p class="landing-dialog-text dialog-password-recovery-reset-message">
        An e-mail containing password recovery instruction has been sent to your e-mail address
        <strong>{{ emailSentTo }}</strong>
        <span>.</span>
      </p>
    </ng-container>
    <ng-template #recoveryFormRef>
      <bp-landing-dialog-title dialogTitle="Password Recovery"></bp-landing-dialog-title>
      <form [formGroup]="recoveryForm" class="password-recovery-form" (ngSubmit)="resetPassword()" novalidate>
        <bp-input-logo
          icon="email"
          placeholder="E-mail"
          name="email"
          type="email"
          autocomplete="email"
          formControlName="email"
          [errorMap]="errorMap"
        ></bp-input-logo>
        <div *ngFor="let error of apiErrors" class="dialog-error dialog-notes dialog-password-recovery-errors">
          {{ error }}
        </div>
        <div class="dialog-password-recovery-button">
          <bp-landing-dialog-button
            type="submit"
            [disabled]="recoveryForm.invalid || recoveryForm.disabled || submitButtonDisabled"
          >
            Recover
          </bp-landing-dialog-button>
        </div>
      </form>
    </ng-template>
  </div>
</bp-landing-dialog>
