import { NgClass } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'bp-landing-dialog',
  templateUrl: './landing-dialog.component.html',
  styleUrls: ['./landing-dialog.component.scss'],
  standalone: true,
  imports: [NgClass],
})
export class LandingDialogComponent {
  @Input() style?: string;
  @Output() closePressed = new EventEmitter<void>();

  onDialogClose() {
    this.closePressed.emit();
  }
}
