<div
  #tooltip
  [ngClass]="{ 'landing-tooltip': landingTooltip }"
  [className]="['tooltip ' + positioning]"
  [style.top.px]="offsetTop"
  [style.left.px]="offsetLeft"
  [style.max-width.px]="maxWidth ? maxWidth : ''"
  (mouseleave)="addMouseOutEventListenerAndClose()"
>
  <div
    *ngIf="!landingTooltip"
    [ngClass]="['tooltip-arrow', positioning, style]"
    [style.top.px]="arrowOffsetTop"
    [style.left.px]="arrowOffsetLeft"
  ></div>
  <div
    [ngClass]="{ 'landing-tooltip-body': landingTooltip }"
    [className]="['tooltip-body ' + positioning + ' ' + style]"
  >
    <ng-content></ng-content>
  </div>
</div>
