import { NgClass, NgFor, NgIf } from '@angular/common';
import { Component, ElementRef, HostListener, Input, OnChanges, SimpleChanges, ViewChild } from '@angular/core';
import { ControlValueAccessor, FormsModule, NgControl, ValidationErrors } from '@angular/forms';
import { PasswordRequirementsComponent } from '../password-requirements/password-requirements.component';
import { TooltipComponent } from '../tooltip/tooltip.component';

@Component({
  selector: 'bp-input-logo',
  templateUrl: './input-logo.component.html',
  styleUrls: ['./input-logo.component.scss'],
  standalone: true,
  imports: [NgClass, NgIf, FormsModule, NgFor, TooltipComponent, PasswordRequirementsComponent],
})
export class InputLogoComponent implements ControlValueAccessor, OnChanges {
  @ViewChild('input') input?: ElementRef;
  @Input() icon?: 'email' | 'lock' | 'phone';
  @Input() errorMap?: Map<string, string>;
  @Input() requirementMap?: Map<string, string>;
  @Input() type: 'email' | 'password' | 'text' = 'text';
  @Input() autocomplete: 'off' | 'email' | 'new-password' | 'current-password' = 'off';
  @Input() maxLength: number = 999;
  @Input() placeholder: string = '';
  @Input() name?: string;
  @Input() passReqShow?: boolean;

  value: string;
  showRequirements = false;
  tooltipPosition: 'landing-bottom' | 'right' = 'right';

  // ControlValueAccessor callback
  private onChange = (value: string | null) => {};

  ngOnChanges(changes: SimpleChanges) {
    if (!this.passReqShow) {
      this.onFocusOut();
    }
  }

  get errors(): string[] {
    const validationErrors: ValidationErrors | null = this.ngControl.errors;
    if (this.ngControl.errors !== null) {
      const requirementKeys = Array.from(this.requirementMap?.keys() || []);
      const errorKeys = Object.keys(validationErrors!).filter((errorKey) => {
        // Don't display requirements as errors.
        return !requirementKeys.includes(errorKey);
      });
      return errorKeys.map((errorKey) => this.errorMap?.get(errorKey) || errorKey);
    } else {
      return [];
    }
  }

  constructor(public ngControl: NgControl) {
    this.ngControl.valueAccessor = this;
    this.setTooltipPositioning();
  }

  get shouldShowInvalid(): boolean {
    return !!this.ngControl.invalid && !!this.ngControl.touched;
  }

  focusInput(): void {
    this.input?.nativeElement.focus();
  }

  onInput() {
    const newValue = this.input?.nativeElement.value;
    this.onChange(newValue);
  }

  onBlur() {
    this.ngControl.control?.markAsTouched();
  }

  onFocusIn() {
    setTimeout(() => {
      this.showRequirements = !!this.requirementMap;
    }, 0);
  }

  onFocusOut() {
    this.showRequirements = false;
  }

  // ControlValueAccessor methods

  writeValue(value: string) {
    this.value = value;
    this.onChange(value);
  }

  registerOnChange(onChange: any) {
    this.onChange = onChange;
  }

  registerOnTouched(onTouched: any) {}

  setDisabledState(isDisabled: boolean): void {
    if (this.input) {
      this.input!.nativeElement.disabled = isDisabled;
    }
  }

  @HostListener('window:resize', ['$event'])
  setTooltipPositioning(): void {
    if (window.innerWidth <= 1150) {
      this.tooltipPosition = 'landing-bottom';
    } else {
      this.tooltipPosition = 'right';
    }
  }
}
