import { NgIf } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { LandingDialogTitleComponent } from '../../../shared/components/dialogs/landing-dialog-title/landing-dialog-title.component';
import { TwoFactorAuthForLoginComponent } from '../../../shared/components/two-factor-auth-input/two-factor-auth-for-login.component';
import { SUPPORT_EMAIL } from '../../../shared/links';
import { TwoFaType } from '../../../shared/models/api/auth.model';

@Component({
  selector: 'bp-two-fa-login-dialog',
  templateUrl: './two-fa-login-dialog.component.html',
  styleUrls: ['./two-fa-login-dialog.component.scss'],
  standalone: true,
  imports: [LandingDialogTitleComponent, NgIf, TwoFactorAuthForLoginComponent],
})
export class TwoFaLoginDialogComponent {
  @Input() twoFaType: TwoFaType;
  @Input() twoFAErrorMessage: string;
  @Input() twoFAErrorShown: boolean;
  @Input() isInputDisabled = false;
  @Output() tokenFilled: EventEmitter<string> = new EventEmitter();
  supportEmail = SUPPORT_EMAIL;
}
