import { AppConfigModel } from './app/shared/models/auxiliary/app-config.model';

/**
 * This is workaround for loading configuration.
 *
 * There are some bugs in Angular and ngrx with APP_INITIALIZER. App doesn't wait until initializer finishes,
 * which leads to services being instantiated with incomplete configuration.
 *
 * @see https://github.com/angular/angular/issues/23279
 * @see https://stackoverflow.com/questions/52544034/app-initializer-not-blocking-imported-modules
 * @see https://github.com/ngrx/platform/issues/931
 */
interface AppConfigInterface {
  config: AppConfigModel | null;
}

export const APP_CONFIG: AppConfigInterface = {
  config: null,
};
