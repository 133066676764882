<div class="wizard-step__header">
  <h2 class="bp-heading">Upload company documents</h2>
  <div class="upload-info-message extra-margin" *ngIf="!merchantProfile?.verificationAdminComment; else adminComment">
    <div class="info-box" *ngIf="merchantProfile?.type === 'PERSON'">
      <div class="info-box-image">
        <div class="i-logo"></div>
      </div>
      <ul class="required-document-list">
        <li>scan of trade licence or certificate</li>
      </ul>
    </div>
    <div class="info-box" *ngIf="merchantProfile?.type === 'COMPANY'">
      <div class="info-box-image">
        <div class="i-logo"></div>
      </div>
      <ul class="required-document-list">
        <li>
          scan of extract from the business register showing company ID, address, director(s) and shareholder(s), no
          older than 6 months
        </li>
        <li>
          a list of all beneficial owners showing their name, surname, personal identification number, date of birth,
          permanent address, nationality, type and number of identity document;
        </li>
        <li>
          scan of bank statement showing company name, address and transaction history, no older than 3 months; and
        </li>
        <li>any trade licence(s) or certificates if applicable</li>
      </ul>
    </div>
  </div>
  <ng-template #adminComment>
    <div class="upload-info-message extra-margin">
      <div class="info-box">
        <div class="info-box-image">
          <div class="i-logo"></div>
        </div>
        <div [innerHTML]="merchantProfile.verificationAdminComment"></div>
      </div>
    </div>
  </ng-template>
</div>
<div class="info-box extra-margin">
  <div class="info-box-image">
    <div class="i-logo"></div>
  </div>
  We support documents in English, Slovak and Czech language. If your documents are in another language, you can submit
  notarized translations in a supported language.
</div>

<div class="link-field" *ngIf="merchantProfile?.type === 'COMPANY'">
  <input
    class="confirmo-platform-input link-field__input"
    placeholder="Link to the commercial register"
    [(ngModel)]="link"
  />
</div>
<br />
<h2 class="bp-subheading-new extra-margin">Uploaded documents</h2>

<div class="row">
  <div>
    <input type="file" class="hidden" (change)="filesChanged($event)" multiple #fileInput />
  </div>
</div>
<div class="confirmo-row display-block-on-mobile">
  <div class="col extra-margin">
    <div
      class="drag-and-drop"
      [ngClass]="dragOver ? 'drag-and-drop__active' : ''"
      bpDragAndDropUpload
      (uploadedFiles)="addFiles($event)"
      (dragOver)="setDragOver($event)"
    >
      <ng-container *ngIf="!dragOver">
        <mat-icon
          class="drag-and-drop__active-content drag-and-drop__icon drag-and-drop__blue"
          aria-hidden="false"
          aria-label="File upload icon"
        >
          add_circle_outline
        </mat-icon>
        <h4 class="drag-and-drop__title">Drag and drop files</h4>
        <p class="drag-and-drop__text">or click to send up to {{ MAX_FILE_AMOUNT }} documents</p>
        <button
          class="confirmo-button confirmo-button__blue drag-and-drop__button"
          (click)="fileInput.click()"
          [disabled]="uploadMoreDocumentDisabled"
        >
          Select files to upload
        </button>
      </ng-container>
      <ng-container *ngIf="dragOver">
        <mat-icon
          class="drag-and-drop__active-content drag-and-drop__icon drag-and-drop__blue"
          aria-hidden="false"
          aria-label="File upload icon"
        >
          upload
        </mat-icon>
        <h4 class="drag-and-drop__title drag-and-drop__active-content">Drop your files</h4>
      </ng-container>
    </div>
  </div>
  <div class="col extra-margin">
    <div *ngFor="let file of files" class="file">
      <div class="file__content">
        <div class="file-icon__wrapper">
          <div class="file-icon"></div>
        </div>
        <div class="margin-left">
          <h4 class="file__name">{{ file.fileName }}</h4>
        </div>
      </div>
      <div class="file__icon">
        <ng-container [ngSwitch]="file.verificationStatus">
          <span *ngSwitchCase="'VERIFIED'" class="success">
            <mat-icon matTooltip="Approved">check_circle</mat-icon>
          </span>
          <span *ngSwitchCase="'REJECTED'" class="rejected">
            <mat-icon matTooltip="Rejected">clear</mat-icon>
          </span>
          <span *ngSwitchDefault class="schedule">
            <mat-icon matTooltip="Approving... may take up to 48 hours">schedule</mat-icon>
          </span>
        </ng-container>
      </div>
    </div>
  </div>
</div>
<div class="confirmo-row display-block-on-mobile center-row">
  <div class="button-wrapper">
    <button
      class="confirmo-button confirmo-button__white-blue extra-margin"
      *ngIf="showBackButton"
      (click)="stepBack()"
    >
      Back
    </button>
    <button class="confirmo-button confirmo-button__blue extra-margin" (click)="finish()" [disabled]="finishDisabled">
      FINISH
    </button>
  </div>
</div>
