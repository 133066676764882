import { Routes } from '@angular/router';
import { MetaGuard } from './shared/guards/meta.guard';
import { PlatformGuard } from './shared/guards/platform.guard';

export const appRoutes: Routes = [
  {
    path: '',
    canActivateChild: [MetaGuard],
    children: [
      {
        path: 'platform',
        data: { meta: { robots: 'noindex' } },
        loadChildren: () => import('./platform/platform.routes').then((m) => m.platformRoutes),
        canActivateChild: [PlatformGuard],
      },
      {
        path: 'public',
        data: { meta: { robots: 'noindex' } },
        loadChildren: () => import('./public/public.routes').then((m) => m.publicRoutes),
      },
      {
        path: '',
        data: { meta: { robots: 'index,follow' } },
        loadChildren: () => import('./landing/landing.routes').then((m) => m.landingRoutes),
      },
      { path: '**', redirectTo: '/home', pathMatch: 'full' },
    ],
  },
];
