import { UntypedFormControl } from '@angular/forms';

export const PASSWORD_MIN_LENGTH = 8;

export function passwordValidator(control: UntypedFormControl): { [key: string]: boolean } {
  const controlValue = control.value ? control.value : '';
  const validationErrors = {
    ...(controlValue.length >= PASSWORD_MIN_LENGTH ? null : { passLength: true }),
    ...(/[A-Z]/.test(controlValue) ? null : { passUpper: true }),
    ...(/[a-z]/.test(controlValue) ? null : { passLower: true }),
    ...(/[0-9]/.test(controlValue) ? null : { passDigits: true }),
  };
  if (controlValue !== '' && Object.keys(validationErrors).length > 0) {
    return { ...validationErrors, ...{ password: true } };
  } else {
    return validationErrors;
  }
}
