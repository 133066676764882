import { NgIf } from '@angular/common';
import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { closeDialogWithAnimation } from '../../../dialogs-utils';
import { AbstractComponent } from '../../abstract.component';
import { CONFIRMATION_DEFAULT_DIALOG_CONFIG } from '../confirmation-default-dialog-config';
import { ConfirmationDialogData } from '../confirmation-dialog-data.model';
import { CloseReason, DialogResult } from '../dialog-result';
import { HeaderDialogComponent } from '../header-dialog/header-dialog.component';

@Component({
  selector: 'bp-confirm-dialog',
  templateUrl: './confirmation-dialog.component.html',
  styleUrls: ['./confirmation-dialog.component.scss'],
  standalone: true,
  imports: [HeaderDialogComponent, NgIf],
})
export class ConfirmationDialogComponent extends AbstractComponent {
  public labels: ConfirmationDialogData = CONFIRMATION_DEFAULT_DIALOG_CONFIG;

  constructor(
    protected dialogRef: MatDialogRef<ConfirmationDialogComponent>,
    @Inject(MAT_DIALOG_DATA) protected data: ConfirmationDialogData,
  ) {
    super();
    this.labels = {
      ...CONFIRMATION_DEFAULT_DIALOG_CONFIG,
      ...this.data,
    };
  }

  public confirm(): void {
    closeDialogWithAnimation(this.dialogRef, new DialogResult(CloseReason.SUCCESS));
  }

  public cancel(): void {
    closeDialogWithAnimation(this.dialogRef, new DialogResult(CloseReason.CANCEL));
  }

  closeDialog(): void {
    closeDialogWithAnimation(this.dialogRef, new DialogResult(CloseReason.CANCEL));
  }
}
