/** @deprecated Will be replaced by Dotfile integration CBP-5349 */
export enum MerchantProfileVerificationStatus {
  /** Merchant is not verified and has not even started verification process. */
  NOT_VERIFIED = 'NOT_VERIFIED',
  /** Merchant has entered basic company information. */
  BASIC_INFO_ENTERED = 'BASIC_INFO_ENTERED',
  /** User has uploaded documents for verification. Now it is up to Admin to verify them.*/
  DOCUMENTS_CHECK = 'DOCUMENTS_CHECK',
  /** Admin asks merchant to upload some more documents.*/
  DOCUMENTS_NEEDED = 'DOCUMENTS_NEEDED',
  /** Merchant was successfully verified.*/
  VERIFIED = 'VERIFIED',
  /** Merchant was rejected.*/
  REJECTED = 'REJECTED',
  /** Merchant is being verified by Idenfy.*/
  IDENFY_VERIFICATION = 'IDENFY_VERIFICATION',
  /** Idenfy verification is completed.*/
  IDENFY_COMPLETED = 'IDENFY_COMPLETED',
}
