import { NgIf } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'bp-generic-dialog',
  templateUrl: './generic-dialog.component.html',
  styleUrls: ['./generic-dialog.component.scss'],
  standalone: true,
  imports: [NgIf],
})
export class GenericDialogComponent {
  @Input()
  isUnclosable = false;

  @Output()
  closePressed = new EventEmitter<void>();

  closeDialog(): void {
    this.closePressed.emit();
  }
}
