import { NgFor, NgIf } from '@angular/common';
import { Component } from '@angular/core';
import { FormsModule, ReactiveFormsModule, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { takeUntil } from 'rxjs/operators';
import { TwoFaLoginDialogComponent } from '../../../../landing/dialogs/two-fa-login-dialog/two-fa-login-dialog.component';
import { loginAction, twoFactorAuthAction } from '../../../actions/auth.actions';
import { addCloseDialogWithAnimationOnBackdropClickSubscriber, closeDialogWithAnimation } from '../../../dialogs-utils';
import { LoginDialogCloseReason } from '../../../dialogs/enums/login-dialog-close-reason.enum';
import { LoginDialogCloseDataModel } from '../../../dialogs/models/login-dialog-close-data.model';
import { LoginPrefillReason } from '../../../enums/login-prefill-reason.enum';
import { TwoFaType } from '../../../models/api/auth.model';
import { ErrorModel } from '../../../models/api/error.model';
import { AppStateModel } from '../../../models/auxiliary/app-state.model';
import { selectAuth } from '../../../selectors/auth.selector';
import { selectLoginPrefill } from '../../../selectors/login-prefill.selector';
import { EmailRecoveryService } from '../../../services/email-recovery.service';
import { emailValidator } from '../../../validators/email.validator';
import { AbstractComponent } from '../../abstract.component';
import { InputLogoComponent } from '../../input-logo/input-logo.component';
import { LandingDialogButtonComponent } from '../../landing-button/landing-dialog-button.component';
import { LandingDialogTitleComponent } from '../landing-dialog-title/landing-dialog-title.component';
import { LandingDialogComponent } from '../landing-dialog/landing-dialog.component';

@Component({
  selector: 'bp-login-dialog',
  templateUrl: './login-dialog.component.html',
  styleUrls: ['./login-dialog.component.scss'],
  standalone: true,
  imports: [
    NgIf,
    LandingDialogComponent,
    TwoFaLoginDialogComponent,
    LandingDialogTitleComponent,
    FormsModule,
    ReactiveFormsModule,
    InputLogoComponent,
    NgFor,
    LandingDialogButtonComponent,
  ],
})
export class LoginDialogComponent extends AbstractComponent {
  loginForm: UntypedFormGroup;
  apiErrors: string[] = [];
  justActivated = false;
  passwordChanged = false;
  twoFADialogShown = false;
  twoFAErrorShown = false;
  twoFAErrorMessage: string;
  twoFaType: TwoFaType;
  email: string;
  password: string;
  isFormSubmitted = false;

  readonly passwordErrorMap = new Map<string, string>([['required', 'Password required']]);
  readonly emailErrorMap = new Map<string, string>([
    ['required', 'E-mail address required'],
    ['email', 'Invalid e-mail address'],
  ]);
  twoFaCodeLoginDispatched = false;

  constructor(
    private store: Store<AppStateModel>,
    private router: Router,
    private fb: UntypedFormBuilder,
    private emailRecoveryService: EmailRecoveryService,
    public loginDialogRef: MatDialogRef<LoginDialogComponent, LoginDialogCloseDataModel>,
  ) {
    super();
    this.displayCaptchaBadge();
    this.unsubscribe$.subscribe(() => {
      this.hideCaptchaBadge();
    });

    addCloseDialogWithAnimationOnBackdropClickSubscriber(loginDialogRef);

    this.loginForm = this.fb.group({
      email: ['', [Validators.required, emailValidator]],
      password: ['', Validators.required],
    });

    this.store
      .select(selectLoginPrefill)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((state) => {
        if (state.errors !== null) {
          this.apiErrors = state.errors.map((error: ErrorModel) => error.message);
          return;
        }

        if (state.data) {
          this.apiErrors = [];
          this.loginForm.get('email').setValue(state.data.email);
          switch (state.data.reason) {
            case LoginPrefillReason.USER_ACTIVATION:
              this.justActivated = true;
              break;
            case LoginPrefillReason.PASSWORD_RESET:
              this.passwordChanged = true;
              break;
          }
        }
      });

    this.store
      .select(selectAuth)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((state) => {
        this.isFormSubmitted = false;

        if (state.data?.invalidToken) {
          return;
        }

        if (state.errors !== null) {
          if (this.twoFADialogShown) {
            this.twoFAErrorMessage = state.errors.map((error: ErrorModel) => error.message)[0];
            this.twoFAErrorShown = true;
            this.twoFaCodeLoginDispatched = false;
          } else {
            this.apiErrors = state.errors.map((error: ErrorModel) => error.message);
          }
          return;
        }

        if (state.data) {
          if (state.data.authorized) {
            this.email = null;
            this.password = null;

            if (this.router.url.includes('/platform')) {
              window.location.reload();
            } else {
              this.router.navigateByUrl('/platform');
            }

            if (!state.errors) {
              this.closeLoginDialog(LoginDialogCloseReason.AUTO_CLOSE);
            }
          } else {
            this.twoFaType = state.data.twoFaRequiredType;
            this.twoFADialogShown = true;
          }
        }
      });
  }

  sendTwoFAToken(token: string): void {
    if (!this.twoFaCodeLoginDispatched) {
      this.twoFaCodeLoginDispatched = true;
      this.store.dispatch(
        twoFactorAuthAction({
          password: this.password,
          email: this.email,
          otp: token,
        }),
      );
    }
  }

  login(): void {
    if (this.isFormSubmitted) {
      return;
    }

    this.apiErrors = [];
    this.email = this.loginForm.get('email').value;
    this.password = this.loginForm.get('password').value;
    this.store.dispatch(loginAction(this.loginForm.value));
    this.isFormSubmitted = true;
  }

  get emailInvalid(): boolean {
    const emailControl = this.loginForm.get('email');
    return (
      emailControl.dirty && emailControl.value !== '' && emailControl.errors !== null && emailControl.errors.pattern
    );
  }

  get emailNotEntered(): boolean {
    const emailControl = this.loginForm.get('email');
    return emailControl.dirty && emailControl.errors !== null && emailControl.errors.required;
  }

  get passwordNotEntered(): boolean {
    const passwordControl = this.loginForm.get('password');
    return passwordControl.dirty && passwordControl.errors !== null && passwordControl.errors.required;
  }

  displayCaptchaBadge(): void {
    setTimeout(() => {
      const recaptchaBadge = this.recaptchaBadge;

      if (recaptchaBadge) {
        this.recaptchaBadge.style.visibility = 'visible';
      }
    }, 500);
  }

  hideCaptchaBadge(): void {
    const recaptchaBadge = this.recaptchaBadge;

    if (recaptchaBadge) {
      this.recaptchaBadge.style.visibility = 'hidden';
    }
  }

  setEmail(): void {
    this.emailRecoveryService.setEmail(this.loginForm.get('email').value);
  }

  closeLoginDialog(reason: LoginDialogCloseReason): void {
    const closeData: LoginDialogCloseDataModel = {
      reason,
      twoFADialogShown: this.twoFADialogShown,
    };

    closeDialogWithAnimation(this.loginDialogRef, closeData);
  }

  openRecoveryDialog(): void {
    this.hideCaptchaBadge();
    this.closeLoginDialog(LoginDialogCloseReason.PASSWORD_RECOVERY);
  }

  openSignupDialog(): void {
    this.hideCaptchaBadge();
    this.closeLoginDialog(LoginDialogCloseReason.SIGN_UP);
  }

  get recaptchaBadge(): HTMLElement | null {
    return document.getElementsByClassName('grecaptcha-badge').item(0) as HTMLElement;
  }
}
