import { createFeatureSelector, createSelector } from '@ngrx/store';
import { MerchantState } from '../reducers/merchant.reducer';

const selectMerchant = createFeatureSelector<MerchantState>('merchant');

export const selectMerchantData = createSelector(selectMerchant, ({ data }) => data);
export const selectMerchantLoading = createSelector(selectMerchant, ({ loading }) => loading);
export const selectMerchantErrors = createSelector(selectMerchant, ({ errors }) => errors);
export const selectMerchantStatus = createSelector(selectMerchantData, (data) => data?.status ?? null);
export const selectMerchantDisplayedTopUpCurrencies = createSelector(
  selectMerchantData,
  (data) => data?.displayedTopUpCurrencies ?? null,
);
