/* eslint-disable max-classes-per-file */
import { createAction, props } from '@ngrx/store';
import { MerchantProfileType } from '../enums/merchant-profile-type.enum';
import { ErrorModel } from '../models/api/error.model';
import { MerchantProfileUnionModel } from '../models/api/merchant-profile/merchant-profile-union.model';
import { MerchantModel } from '../models/api/merchant.model';

export enum MerchantActionTypes {
  StartMerchantVerificationAdmin = 'merchant/START_MERCHANT_VERIFICATION_ADMIN_REQUEST',
  StartMerchantVerificationAdminSuccess = 'merchant/START_MERCHANT_VERIFICATION_ADMIN_SUCCESS',
  StartMerchantVerificationAdminError = 'merchant/START_MERCHANT_VERIFICATION_ADMIN_ERROR',

  UpdateMerchantProfileAdmin = 'merchant/UPDATE_MERCHANT_PROFILE_ADMIN_REQUEST',
  UpdateMerchantProfileAdminSuccess = 'merchant/UPDATE_MERCHANT_PROFILE_ADMIN_SUCCESS',
  UpdateMerchantProfileAdminError = 'merchant/UPDATE_MERCHANT_PROFILE_ADMIN_ERROR',

  GET_MERCHANT = 'merchant/GET_MERCHANT',
  GET_MERCHANT_SUCCESS = 'merchant/GET_MERCHANT_SUCCESS',
  GET_MERCHANT_ERROR = 'merchant/GET_MERCHANT_ERROR',
}

/** @deprecated Will be replaced by Dotfile integration CBP-5349 */
export const startMerchantVerificationAdminAction = createAction(
  MerchantActionTypes.StartMerchantVerificationAdmin,
  props<{ merchantHashId: string; merchantProfileType: MerchantProfileType }>(),
);

/** @deprecated Will be replaced by Dotfile integration CBP-5349 */
export const startMerchantVerificationAdminSuccessAction = createAction(
  MerchantActionTypes.StartMerchantVerificationAdminSuccess,
);

/** @deprecated Will be replaced by Dotfile integration CBP-5349 */
export const startMerchantVerificationAdminErrorAction = createAction(
  MerchantActionTypes.StartMerchantVerificationAdminError,
  props<{ errors: ErrorModel[] }>(),
);

export const updateMerchantProfileAdminAction = createAction(
  MerchantActionTypes.UpdateMerchantProfileAdmin,
  props<{ merchantProfile: MerchantProfileUnionModel }>(),
);

export const updateMerchantProfileAdminSuccessAction = createAction(
  MerchantActionTypes.UpdateMerchantProfileAdminSuccess,
  props<{ merchantProfile: MerchantProfileUnionModel }>(),
);

export const updateMerchantProfileAdminErrorAction = createAction(
  MerchantActionTypes.UpdateMerchantProfileAdminError,
  props<{ errors: ErrorModel[] }>(),
);

export const getMerchantAction = createAction(MerchantActionTypes.GET_MERCHANT);

export const getMerchantSuccessAction = createAction(
  MerchantActionTypes.GET_MERCHANT_SUCCESS,
  props<{ merchant: MerchantModel }>(),
);

export const getMerchantErrorAction = createAction(
  MerchantActionTypes.GET_MERCHANT_ERROR,
  props<{ errors: ErrorModel[] }>(),
);
