import { DecimalPipe } from '@angular/common';
import { HttpClient, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { ErrorHandler, LOCALE_ID, enableProdMode, importProvidersFrom } from '@angular/core';
import { MatMomentDateModule, MomentDateAdapter } from '@angular/material-moment-adapter';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { BrowserModule, HammerModule, bootstrapApplication } from '@angular/platform-browser';
import { provideAnimations } from '@angular/platform-browser/animations';
import { ActivatedRouteSnapshot, Router } from '@angular/router';
import { EffectsModule } from '@ngrx/effects';
import {
  FullRouterStateSerializer,
  RouterStateSerializer,
  StoreRouterConnectingModule,
  routerReducer,
} from '@ngrx/router-store';
import { Action, ActionReducer, ActionReducerMap, MetaReducer, StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { NgxJsonLdModule } from '@ngx-lite/json-ld';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import * as Sentry from '@sentry/angular';
import { RECAPTCHA_V3_SITE_KEY, RecaptchaV3Module } from 'ng-recaptcha';
import { localStorageSync } from 'ngrx-store-localstorage';
import { ToastrModule } from 'ngx-toastr';
import { AppComponent } from './app/app.component';
import { appConfig } from './app/app.config';
import { CUSTOMIZED_DATE_FORMATS, appActions } from './app/shared/constants';
import { ApiKeyDetailEffect } from './app/shared/effects/api-key-detail.effect';
import { ApiKeyListEffect } from './app/shared/effects/api-key-list.effect';
import { AuthEffect } from './app/shared/effects/auth.effect';
import { BalanceHistoryPageEffect } from './app/shared/effects/balance-history-page.effect';
import { CallbackPasswordCreateEffect } from './app/shared/effects/callback-password-create.effect';
import { CallbackPasswordEffect } from './app/shared/effects/callback-password.effect';
import { ConfigurationListEffect } from './app/shared/effects/configuration-list.effect';
import { CorrectionTxEffect } from './app/shared/effects/correction-tx.effect';
import { CryptoAddressVerifyEffect } from './app/shared/effects/crypto-address-verify.effect';
import { CustomerEmailEffect } from './app/shared/effects/customer-email.effect';
import { DashboardChartEffect } from './app/shared/effects/dashboard-chart.effect';
import { EmailInvoicePageEffect } from './app/shared/effects/email-invoice-page.effect';
import { EmailInvoiceEffect } from './app/shared/effects/email-invoice.effect';
import { FinancialReportEffect } from './app/shared/effects/financial-report.effect';
import { InvoiceExpirationFeeEffect } from './app/shared/effects/invoice-expiration-fee.effect';
import { InvoicePageEffect } from './app/shared/effects/invoice-page.effect';
import { InvoiceEffect } from './app/shared/effects/invoice.effect';
import { LedgerEffect } from './app/shared/effects/ledger.effect';
import { LimitsAndFeesEffect } from './app/shared/effects/limits-and-fees.effect';
import { LoaderEffects } from './app/shared/effects/loader.effects';
import { LoginPrefillEffect } from './app/shared/effects/login-prefill.effect';
import { MarketOperationEffects } from './app/shared/effects/market-operation.effects';
import { MarketPageEffect } from './app/shared/effects/market-page.effect';
import { MerchantApiVersionEffect } from './app/shared/effects/merchant-api-version.effect';
import { MerchantDetailEffect } from './app/shared/effects/merchant-detail.effect';
import { MerchantDocumentEffect } from './app/shared/effects/merchant-document.effect';
import { MerchantFeesEffect } from './app/shared/effects/merchant-fees.effect';
import { MerchantPageEffect } from './app/shared/effects/merchant-page.effect';
import { MerchantPersonEffect } from './app/shared/effects/merchant-person.effect';
import { MerchantProfilePageEffect } from './app/shared/effects/merchant-profile-page.effect';
import { MerchantProfileEffect } from './app/shared/effects/merchant-profile.effect';
import { MerchantVerificationDocumentsEffects } from './app/shared/effects/merchant-verification-documents.effects';
import { MerchantVerificationIdenfyEffect } from './app/shared/effects/merchant-verification-idenfy.effect';
import { MerchantVerificationStatusEffects } from './app/shared/effects/merchant-verification-status.effects';
import { MerchantVerificationEffect } from './app/shared/effects/merchant-verification.effect';
import { MerchantEffects } from './app/shared/effects/merchant.effects';
import { NotificationSettingsEffects } from './app/shared/effects/notification-settings.effects';
import { OpenOrdersStatisticsEffect } from './app/shared/effects/open-orders-statistics.effect';
import { OtherSideTransactionEffects } from './app/shared/effects/other-side-transaction.effects';
import { ParameterEffect } from './app/shared/effects/parameter.effect';
import { PaymentButtonEffects } from './app/shared/effects/payment-button.effects';
import { PaymentSettingsEffect } from './app/shared/effects/payment-settings.effect';
import { PayoutFeesEffect } from './app/shared/effects/payout-fees.effect';
import { RefundPageEffect } from './app/shared/effects/refund-page.effect';
import { PasswordEffect } from './app/shared/effects/stateless/password.effect';
import { TwoFactorAuthEffect } from './app/shared/effects/two-factor-auth.effect';
import { UserDetailEffect } from './app/shared/effects/user-detail.effect';
import { UserEffect } from './app/shared/effects/user-effect';
import { UserGuiSettingsEffect } from './app/shared/effects/user-gui-settings.effect';
import { UserStatsEffect } from './app/shared/effects/user-stats.effect';
import { WebhookInfoListEffect } from './app/shared/effects/webhook-info-list.effect';
import { WizardEffects } from './app/shared/effects/wizard.effects';
import { AuthModel } from './app/shared/models/api/auth.model';
import { AppStateModel } from './app/shared/models/auxiliary/app-state.model';
import { RefundTokenMapModel } from './app/shared/models/auxiliary/customer-token-map.model';
import { apiKeyCreateReducer } from './app/shared/reducers/api-key-create.reducer';
import { apiKeyDetailReducer } from './app/shared/reducers/api-key-detail.reducer';
import { apiKeyListReducer } from './app/shared/reducers/api-key-list.reducer';
import { AuthState, authReducer } from './app/shared/reducers/auth.reducer';
import { balanceHistoryPageReducer } from './app/shared/reducers/balance-history-page.reducer';
import { callbackPasswordCreateReducer } from './app/shared/reducers/callback-password-create.reducer';
import { callbackPasswordReducer } from './app/shared/reducers/callback-password.reducer';
import { configurationListReducer } from './app/shared/reducers/configuration-list.reducer';
import { correctionTxListReducer } from './app/shared/reducers/correction-tx-list.reducer';
import { cryptoAddressVerifyReducer } from './app/shared/reducers/crypto-address-verify.reducer';
import { customerEmailReducer } from './app/shared/reducers/customer-email.reducer';
import { dashboardChartReducer } from './app/shared/reducers/dashboard-chart.reducer';
import { emailInvoicePageReducer } from './app/shared/reducers/email-invoice-page.reducer';
import { emailInvoiceReducer } from './app/shared/reducers/email-invoice.reducer';
import {
  financialReportingInvoicesReducer,
  financialReportingPayoutsReducer,
  reportingUserStatsReducer,
} from './app/shared/reducers/financial-reporting.reducer';
import { InvoiceExpirationFeeReducer } from './app/shared/reducers/invoice-expiration-fee.reducer';
import { invoicePageReducer } from './app/shared/reducers/invoice-page.reducer';
import { invoicePreparedCurrenciesReducer } from './app/shared/reducers/invoice-prepared-currencies.reducer';
import { invoiceReducer } from './app/shared/reducers/invoice.reducer';
import { ledgerReducer } from './app/shared/reducers/ledger.reducer';
import { limitsAndFeesReducer } from './app/shared/reducers/limits-and-fees.reducer';
import { loaderReducer } from './app/shared/reducers/loader.reducer';
import { loginPrefillReducer } from './app/shared/reducers/login-prefill.reducer';
import { marketOperationReducer } from './app/shared/reducers/market-operation.reducer';
import { marketPageReducer } from './app/shared/reducers/market-page.reducer';
import { MaxClicksReducer } from './app/shared/reducers/max-clicks.reducer';
import { merchantApiVersionReducer } from './app/shared/reducers/merchant-api-version.reducer';
import { merchantDetailReducer } from './app/shared/reducers/merchant-detail.reducer';
import { merchantDocumentReducer } from './app/shared/reducers/merchant-document.reducer';
import { merchantFeesReducer } from './app/shared/reducers/merchant-fees.reducer';
import { merchantPageReducer } from './app/shared/reducers/merchant-page.reducer';
import { merchantPersonReducer } from './app/shared/reducers/merchant-person.reducer';
import { merchantProfileAdminReducer } from './app/shared/reducers/merchant-profile-admin.reducer';
import { merchantProfilePageReducer } from './app/shared/reducers/merchant-profile-page.reducer';
import { MerchantProfileReducer } from './app/shared/reducers/merchant-profile.reducer';
import { merchantVerificationDocumentsReducer } from './app/shared/reducers/merchant-verification-documents.reducer';
import {
  merchantVerificationIdenfyGenerate,
  merchantVerificationIdenfyPersonReducer,
} from './app/shared/reducers/merchant-verification-idenfy.reducer';
import { merchantVerificationStatusReducer } from './app/shared/reducers/merchant-verification-status.reducer';
import {
  merchantVerificationAsAdminReducer,
  merchantVerificationAsMerchantReducer,
} from './app/shared/reducers/merchant-verification.reducer';
import { merchantReducer } from './app/shared/reducers/merchant.reducer';
import { notificationSettingsReducer } from './app/shared/reducers/notification-settings.reducer';
import { openOrdersStatisticsReducer } from './app/shared/reducers/open-orders-statistics.reducer';
import { otherSideTransactionLimitsReducer } from './app/shared/reducers/other-side-transaction-limits.reducer';
import { otherSideTransactionListReducer } from './app/shared/reducers/other-side-transaction-page.reducer';
import { otherSideTransactionReducer } from './app/shared/reducers/other-side-transaction.reducer';
import { parameterReducer } from './app/shared/reducers/parameter.reducer';
import { paymentButtonPageReducer } from './app/shared/reducers/payment-button-page.reducer';
import { paymentButtonReducer } from './app/shared/reducers/payment-button.reducer';
import { paymentSettingsDefaultReducer } from './app/shared/reducers/payment-settings-default.reducer';
import { paymentSettingsReducer } from './app/shared/reducers/payment-settings.reducer';
import { payoutFeesReducer } from './app/shared/reducers/payout-fees.reducer';
import { pdfSettlementReducer } from './app/shared/reducers/pdfsettlement.reducer';
import { refundPageReducer } from './app/shared/reducers/refund-page.reducer';
import { RefundTokenMapState, refundTokenMapReducer } from './app/shared/reducers/refund-token-map.reducer';
import { twoFactorAuthReducer } from './app/shared/reducers/two-factor-auth.reducer';
import { userActivationReducer } from './app/shared/reducers/user-activation.reducer';
import { userDetailReducer } from './app/shared/reducers/user-detail.reducer';
import { userGuiSettingsReducer } from './app/shared/reducers/user-gui-settings.reducer';
import { userStatsReducer } from './app/shared/reducers/user-stats.reducer';
import { userReducer } from './app/shared/reducers/user.reducer';
import { webhookInfoListReducer } from './app/shared/reducers/webhook-info-list.reducer';
import { wizardReducer } from './app/shared/reducers/wizard.reducer';
import { ApiService } from './app/shared/services/api.service';
import { AppConfigService } from './app/shared/services/app-config.service';
import { AuthService } from './app/shared/services/authorization/auth.service';
import { RoleFactoryService } from './app/shared/services/authorization/role-factory.service';
import { CookiesService } from './app/shared/services/cookies.service';
import { CustomRouterStoreSerializerService } from './app/shared/services/custom-router-store-serializer.service';
import { InvoiceSocketService } from './app/shared/services/invoice-socket.service';
import { IpAddressService } from './app/shared/services/ip-address.service';
import { IpInfoService } from './app/shared/services/ip-info.service';
import { LocaleService } from './app/shared/services/locale.service';
import { LogService } from './app/shared/services/log-service';
import { MessageService } from './app/shared/services/message.service';
import { TabAccessService } from './app/shared/services/tab-access.service';
import { XmlReportService } from './app/shared/services/xml-report.service';
import { RebalancingEffects } from './app/shared/store/admin/merchants/rebalancing/rebalancing.effects';
import { rebalancingReducer } from './app/shared/store/admin/merchants/rebalancing/rebalancing.reducer';
import { AuditLogEventsAdminEffect } from './app/shared/store/audit-log/admin/audit-log-events-admin.effect';
import { auditLogEventsAdminReducer } from './app/shared/store/audit-log/admin/audit-log-events-admin.reducer';
import { AuditLogEventsEffect } from './app/shared/store/audit-log/merchant/audit-log-events.effect';
import { auditLogEventsReducer } from './app/shared/store/audit-log/merchant/audit-log-events.reducer';
import { MerchantBalanceEffect } from './app/shared/store/balances/merchant-balance.effect';
import { merchantBalanceReducer } from './app/shared/store/balances/merchant-balance.reducer';
import { CountryListEffect } from './app/shared/store/countries/country-list.effect';
import { countryListReducer } from './app/shared/store/countries/country-list.reducer';
import { cryptoNetworksListReducer } from './app/shared/store/crypto-networks/crypto-networks-list.reducer';
import { CryptoNetworksEffect } from './app/shared/store/crypto-networks/crypto-networks.effect';
import { CryptoWalletsStatisticsEffect } from './app/shared/store/crypto-wallets-statistics/crypto-wallets-statistics.effect';
import { cryptoWalletsStatisticsReducer } from './app/shared/store/crypto-wallets-statistics/crypto-wallets-statistics.reducer';
import { CurrencyListEffect } from './app/shared/store/currencies/currency-list.effect';
import { currencyListReducer } from './app/shared/store/currencies/currency-list.reducer';
import { CurrencyService } from './app/shared/store/currencies/currency-list.service';
import { FiatPaymentMethodsEffect } from './app/shared/store/fiat-payment-methods/fiat-payment-methods.effect';
import { fiatPaymentMethodsReducer } from './app/shared/store/fiat-payment-methods/fiat-payment-methods.reducer';
import { FireblocksVaultAccountEffect } from './app/shared/store/fireblocks-vault-account/fireblocks-vault-account.effect';
import { invoicePageFiltersReducer } from './app/shared/store/invoice-page-filters/invoice-page-filters.reducer';
import { ledgerFiltersReducer } from './app/shared/store/ledger-filters/ledger-filters.reducer';
import { MerchantDirectorsEffect } from './app/shared/store/merchant-directors/merchant-directors.effect';
import { merchantDirectorsReducer } from './app/shared/store/merchant-directors/merchant-directors.reducer';
import { merchantPageFiltersReducer } from './app/shared/store/merchant-page-filters/merchant-page-filters.reducer';
import { paymentButtonListFiltersReducer } from './app/shared/store/payment-button-list-filters/payment-button-list-filters.reducer';
import { CryptoPaymentMethodsAdminEffect } from './app/shared/store/payment-methods/admin/crypto-payment-methods-admin.effect';
import { cryptoPaymentMethodsAdminReducer } from './app/shared/store/payment-methods/admin/crypto-payment-methods-admin.reducer';
import { CryptoPaymentMethodsAdminService } from './app/shared/store/payment-methods/admin/crypto-payment-methods-admin.service';
import { CryptoPaymentMethodsMerchantEffect } from './app/shared/store/payment-methods/merchant/crypto-payment-methods-merchant.effect';
import { cryptoPaymentMethodsMerchantReducer } from './app/shared/store/payment-methods/merchant/crypto-payment-methods-merchant.reducer';
import { CryptoPaymentMethodsMerchantService } from './app/shared/store/payment-methods/merchant/crypto-payment-methods-merchant.service';
import { CryptoPaymentMethodsPublicEffect } from './app/shared/store/payment-methods/public/crypto-payment-methods-public.effect';
import { cryptoPaymentMethodsPublicReducer } from './app/shared/store/payment-methods/public/crypto-payment-methods-public.reducer';
import { CryptoPaymentMethodsPublicService } from './app/shared/store/payment-methods/public/crypto-payment-methods-public.service';
import { PayoutFeesPublicEffect } from './app/shared/store/payout-fees-public/payout-fees-public.effect';
import { payoutFeesPublicReducer } from './app/shared/store/payout-fees-public/payout-fees-public.reducer';
import { payoutPageFiltersReducer } from './app/shared/store/payout-page-filters/payout-page-filters.reducer';
import { PosDevicesEffect } from './app/shared/store/pos-devices/pos-devices.effect';
import { posDevicesReducer } from './app/shared/store/pos-devices/pos-devices.reducer';
import { publicInvoicePreparedCurrencyCodeReducer } from './app/shared/store/public-invoice-prepared-currency-code/public-invoice-prepared-currency-code.reducer';
import { PublicProfileEffects } from './app/shared/store/public-profile/public-profile.effects';
import { publicProfileReducer } from './app/shared/store/public-profile/public-profile.reducer';
import { RefundEffect } from './app/shared/store/refund/refund.effect';
import { SettlementMethodNotificationEffects } from './app/shared/store/settlement-method-notifications/settlement-method-notification.effects';
import { settlementMethodNotificationsReducer } from './app/shared/store/settlement-method-notifications/settlement-method-notifications.reducer';
import { SettlementMethodPageEffect } from './app/shared/store/settlement-method-page/settlement-method-page.effect';
import { settlementMethodPageReducer } from './app/shared/store/settlement-method-page/settlement-method-page.reducer';
import { SettlementMethodWizardEffect } from './app/shared/store/settlement-method-wizard/settlement-method-wizard.effect';
import { settlementMethodWizardReducer } from './app/shared/store/settlement-method-wizard/settlement-method-wizard.reducer';
import { SettlementMethodEffect } from './app/shared/store/settlement-method/settlement-method.effect';
import { settlementPageFiltersReducer } from './app/shared/store/settlement-page-filters/settlement-page-filters.reducer';
import { SettlementPageEffect } from './app/shared/store/settlement-page/settlement-page.effect';
import { settlementPageReducer } from './app/shared/store/settlement-page/settlement-page.reducer';
import { SettlementEffect } from './app/shared/store/settlement/settlement.effect';
import { settlementReducer } from './app/shared/store/settlement/settlement.reducer';
import { UserRegistrationEffect } from './app/shared/store/signup/user-registration.effect';
import { SubscriptionDetailEffects } from './app/shared/store/subscription/subscription-detail/subscription-detail.effects';
import { subscriptionDetailReducer } from './app/shared/store/subscription/subscription-detail/subscription-detail.reducers';
import { subscriptionListFiltersReducer } from './app/shared/store/subscription/subscription-list-filters/subscription-list-filters.reducer';
import { SubscriptionListEffects } from './app/shared/store/subscription/subscription-list/subscription-list.effects';
import { subscriptionListReducer } from './app/shared/store/subscription/subscription-list/subscription-list.reducer';
import { TodoListEffect } from './app/shared/store/todo-list/todo-list.effect';
import { todoReducer } from './app/shared/store/todo-list/todo.reducer';
import { UserListAdminEffects } from './app/shared/store/user-list-admin/user-list-admin.effects';
import { userListAdminReducer } from './app/shared/store/user-list-admin/user-list-admin.reducer';
import { UserListEffects } from './app/shared/store/user-list/user-list.effects';
import { userListReducer } from './app/shared/store/user-list/user-list.reducer';
import { CryptoAddressValidators } from './app/shared/validators/crypto-address.validators';
import { APP_CONFIG } from './config';

const REDUCERS: ActionReducerMap<AppStateModel> = {
  apiKeyList: apiKeyListReducer,
  apiKeyDetail: apiKeyDetailReducer,
  apiKeyCreate: apiKeyCreateReducer,
  auth: authReducer,
  auditLogEvents: auditLogEventsReducer,
  auditLogEventsAdmin: auditLogEventsAdminReducer,
  cryptoNetworksList: cryptoNetworksListReducer,
  balanceHistoryPage: balanceHistoryPageReducer,
  cryptoWalletsStatistics: cryptoWalletsStatisticsReducer,
  callbackPassword: callbackPasswordReducer,
  callbackPasswordCreate: callbackPasswordCreateReducer,
  countryList: countryListReducer,
  configurationList: configurationListReducer,
  cryptoAddressVerify: cryptoAddressVerifyReducer,
  currencyList: currencyListReducer,
  customerEmail: customerEmailReducer,
  correctionTxList: correctionTxListReducer,
  cryptoPaymentMethodsAdmin: cryptoPaymentMethodsAdminReducer,
  cryptoPaymentMethodsMerchant: cryptoPaymentMethodsMerchantReducer,
  cryptoPaymentMethodsPublic: cryptoPaymentMethodsPublicReducer,
  dashboardChart: dashboardChartReducer,
  emailInvoicePage: emailInvoicePageReducer,
  emailInvoice: emailInvoiceReducer,
  fiatPaymentMethods: fiatPaymentMethodsReducer,
  financialReportInvoices: financialReportingInvoicesReducer,
  financialReportPayouts: financialReportingPayoutsReducer,
  invoice: invoiceReducer,
  invoicePage: invoicePageReducer,
  invoicePageFilters: invoicePageFiltersReducer,
  invoiceExpirationFee: InvoiceExpirationFeeReducer,
  invoicePreparedCurrencies: invoicePreparedCurrenciesReducer,
  idcheckVerificationPerson: merchantVerificationIdenfyPersonReducer,
  idcheckVerificationData: merchantVerificationIdenfyGenerate,
  limitsAndFees: limitsAndFeesReducer,
  loginPrefill: loginPrefillReducer,
  ledger: ledgerReducer,
  ledgerFilters: ledgerFiltersReducer,
  loader: loaderReducer,
  maxClicks: MaxClicksReducer,
  merchant: merchantReducer,
  merchantBalance: merchantBalanceReducer,
  merchantFees: merchantFeesReducer,
  merchantPage: merchantPageReducer,
  merchantPageFilters: merchantPageFiltersReducer,
  merchantDetail: merchantDetailReducer,
  merchantProfile: MerchantProfileReducer,
  merchantProfilePage: merchantProfilePageReducer,
  merchantDocument: merchantDocumentReducer,
  merchantDirectors: merchantDirectorsReducer,
  marketPage: marketPageReducer,
  merchantVerificationAsAdmin: merchantVerificationAsAdminReducer,
  merchantVerificationAsMerchant: merchantVerificationAsMerchantReducer,
  merchantVerificationStatus: merchantVerificationStatusReducer,
  merchantVerificationDocuments: merchantVerificationDocumentsReducer,
  marketOperation: marketOperationReducer,
  merchantApiVersion: merchantApiVersionReducer,
  merchantPerson: merchantPersonReducer,
  merchantProfileAdmin: merchantProfileAdminReducer,
  notificationSettings: notificationSettingsReducer,
  openOrdersStatistics: openOrdersStatisticsReducer,
  otherSideTransaction: otherSideTransactionReducer,
  otherSideTransactionList: otherSideTransactionListReducer,
  otherSideTransactionLimits: otherSideTransactionLimitsReducer,
  paymentButtonListFilters: paymentButtonListFiltersReducer,
  paymentSettingsDefault: paymentSettingsDefaultReducer,
  paymentSettings: paymentSettingsReducer,
  payoutPageFilters: payoutPageFiltersReducer,
  payoutFeesPublic: payoutFeesPublicReducer,
  pdfSettlement: pdfSettlementReducer,
  payoutFees: payoutFeesReducer,
  paymentButtonPage: paymentButtonPageReducer,
  paymentButton: paymentButtonReducer,
  publicProfile: publicProfileReducer,
  publicInvoicePreparedCurrencyCode: publicInvoicePreparedCurrencyCodeReducer,
  parameter: parameterReducer,
  rebalancingGroup: rebalancingReducer,
  refundPage: refundPageReducer,
  refundTokenMap: refundTokenMapReducer,
  router: routerReducer,
  settlementMethodWizard: settlementMethodWizardReducer,
  settlementMethodPage: settlementMethodPageReducer,
  settlementPage: settlementPageReducer,
  settlementPageFilters: settlementPageFiltersReducer,
  settlementMethodNotifications: settlementMethodNotificationsReducer,
  settlement: settlementReducer,
  twoFactorAuthReducer: twoFactorAuthReducer,
  todoReducer: todoReducer,
  user: userReducer,
  userGuiSettings: userGuiSettingsReducer,
  userList: userListReducer,
  userListAsAdmin: userListAdminReducer,
  userDetail: userDetailReducer,
  userActivation: userActivationReducer,
  userStats: userStatsReducer,
  userStatsReport: reportingUserStatsReducer,
  webhookInfoList: webhookInfoListReducer,
  wizard: wizardReducer,
  posDevices: posDevicesReducer,
  subscriptionList: subscriptionListReducer,
  subscriptionListFilters: subscriptionListFiltersReducer,
  subscriptionDetail: subscriptionDetailReducer,
};

const STORAGE_SYNC_OPTIONS = {
  rehydrate: true,
  removeOnUndefined: true,
  keys: [
    {
      auth: {
        serialize: (state: AuthState): AuthModel =>
          state.data && state.data.authorized ? { token: state.data.token } : undefined,
        deserialize: (data: AuthModel): AuthState => ({
          data: data && { token: data.token, authorized: true },
          errors: null,
        }),
      },
    },
    {
      refundTokenMap: {
        serialize: (state: RefundTokenMapState): RefundTokenMapModel => state.data,
        deserialize: (data: RefundTokenMapModel): RefundTokenMapState => ({ data, errors: null }),
      },
    },
  ],
};

function appStateReducer(reducer: ActionReducer<any>): ActionReducer<any> {
  return function (appState: AppStateModel, action: Action): AppStateModel {
    const combinedReducer = (syncedState: AppStateModel, syncedAction: Action): AppStateModel => {
      const rehydratedState: AppStateModel = {
        ...syncedState,
        refundTokenMap: { errors: null, data: JSON.parse(localStorage.getItem('refundTokenMap')) },
      };
      const clearedState =
        syncedAction.type === appActions.CLEAR
          ? { auth: rehydratedState.auth, refundTokenMap: rehydratedState.refundTokenMap }
          : rehydratedState;
      return reducer(clearedState, syncedAction);
    };

    return localStorageSync(STORAGE_SYNC_OPTIONS)(combinedReducer)(appState, action);
  };
}

function createTranslateLoader(http: HttpClient): TranslateHttpLoader {
  return new TranslateHttpLoader(
    http,
    './assets/public-invoice/translations/',
    '.json' + '?timestamp=' + new Date().getTime(),
  );
}

const metaReducers: MetaReducer<AppStateModel>[] = [appStateReducer];

/**
 * This is workaround to load configuration dynamically.
 * For more details, see config.ts file.
 *
 * Warning: This might bring some issues in AOT mode.
 */
fetch('/assets/app-config.json')
  .then(async (response) => {
    const json = await response.json();

    APP_CONFIG.config = json;

    if (json.production) {
      enableProdMode();
    }
  })
  .catch(() => {
    console.warn('Could not load app-config.json');
  })
  .finally(() => {
    bootstrapApplication(AppComponent, {
      providers: [
        ...appConfig.providers,
        importProvidersFrom(
          BrowserModule,
          StoreModule.forRoot(REDUCERS, {
            metaReducers,
            runtimeChecks: {
              strictStateImmutability: false,
              strictActionImmutability: false,
              // FIXME - current setting is prone to bugs
              // strictStateImmutability: true,
              // strictActionImmutability: true,
              // strictActionSerializability: true,
              // strictStateSerializability: true,
              // strictActionTypeUniqueness: true,
            },
          }),
          StoreDevtoolsModule.instrument({ connectInZone: true }),
          MatMomentDateModule,
          EffectsModule.forRoot([
            ApiKeyListEffect,
            ApiKeyDetailEffect,
            MerchantApiVersionEffect,
            AuthEffect,
            AuditLogEventsEffect,
            AuditLogEventsAdminEffect,
            CryptoNetworksEffect,
            CallbackPasswordEffect,
            CallbackPasswordCreateEffect,
            CountryListEffect,
            CryptoAddressVerifyEffect,
            CryptoPaymentMethodsAdminEffect,
            CryptoPaymentMethodsMerchantEffect,
            CryptoPaymentMethodsPublicEffect,
            CryptoWalletsStatisticsEffect,
            CurrencyListEffect,
            CustomerEmailEffect,
            InvoicePageEffect,
            InvoiceEffect,
            EmailInvoicePageEffect,
            EmailInvoiceEffect,
            FireblocksVaultAccountEffect,
            FiatPaymentMethodsEffect,
            LoginPrefillEffect,
            LimitsAndFeesEffect,
            MerchantFeesEffect,
            UserEffect,
            MerchantBalanceEffect,
            PaymentSettingsEffect,
            LedgerEffect,
            InvoiceExpirationFeeEffect,
            RefundPageEffect,
            SettlementMethodWizardEffect,
            SettlementMethodPageEffect,
            SettlementPageEffect,
            SettlementEffect,
            TodoListEffect,
            TwoFactorAuthEffect,
            WebhookInfoListEffect,
            MerchantPageEffect,
            MerchantDetailEffect,
            MerchantProfileEffect,
            MerchantProfilePageEffect,
            MerchantDocumentEffect,
            MerchantPersonEffect,
            MarketPageEffect,
            CorrectionTxEffect,
            ConfigurationListEffect,
            UserGuiSettingsEffect,
            PasswordEffect,
            SettlementMethodEffect,
            RefundEffect,
            UserRegistrationEffect,
            MerchantDirectorsEffect,
            SettlementMethodNotificationEffects,
            MerchantVerificationStatusEffects,
            MerchantVerificationDocumentsEffects,
            WizardEffects,
            LoaderEffects,
            OtherSideTransactionEffects,
            UserListEffects,
            UserListAdminEffects,
            UserDetailEffect,
            NotificationSettingsEffects,
            PaymentButtonEffects,
            PublicProfileEffects,
            MerchantEffects,
            MarketOperationEffects,
            BalanceHistoryPageEffect,
            PayoutFeesEffect,
            PayoutFeesPublicEffect,
            DashboardChartEffect,
            UserStatsEffect,
            FinancialReportEffect,
            ParameterEffect,
            MerchantVerificationIdenfyEffect,
            OpenOrdersStatisticsEffect,
            PosDevicesEffect,
            SubscriptionListEffects,
            SubscriptionDetailEffects,
            RebalancingEffects,
            MerchantVerificationEffect,
          ]),
          ToastrModule.forRoot({
            timeOut: 30000,
            extendedTimeOut: 0,
            closeButton: true,
            tapToDismiss: false,
          }),
          // Connects RouterModule with StoreModule
          StoreRouterConnectingModule.forRoot({ serializer: FullRouterStateSerializer }),
          RecaptchaV3Module,
          NgxJsonLdModule,
          HammerModule,
          TranslateModule.forRoot({
            loader: {
              provide: TranslateLoader,
              useFactory: createTranslateLoader,
              deps: [HttpClient],
            },
          }),
        ),
        AppConfigService,
        RoleFactoryService,
        AuthService,
        MessageService,
        ApiService,
        LogService,
        IpAddressService,
        IpInfoService,
        InvoiceSocketService,
        XmlReportService,
        CurrencyService,
        CryptoPaymentMethodsMerchantService,
        CryptoPaymentMethodsAdminService,
        CryptoPaymentMethodsPublicService,
        CryptoAddressValidators,
        TabAccessService,
        DecimalPipe,
        CookiesService,
        {
          provide: RouterStateSerializer,
          useClass: CustomRouterStoreSerializerService,
        },
        {
          provide: DateAdapter,
          useClass: MomentDateAdapter,
          deps: [MAT_DATE_LOCALE],
        },
        {
          provide: MAT_DATE_FORMATS,
          useValue: CUSTOMIZED_DATE_FORMATS,
        },
        {
          provide: LOCALE_ID,
          useValue: 'en-US',
        },
        LocaleService,
        {
          provide: RECAPTCHA_V3_SITE_KEY,
          useValue: '6LcU2ucUAAAAAISqu7xrMYE7XiGp5mnvwnzb9F3p',
        },
        {
          provide: ErrorHandler,
          useValue: Sentry.createErrorHandler(),
        },
        {
          provide: Sentry.TraceService,
          deps: [Router],
        },
        {
          provide: 'externalUrlRedirectResolver',
          useValue: (route: ActivatedRouteSnapshot) => {
            window.location.href = (route.data as any).externalUrl;
          },
        },
        provideHttpClient(withInterceptorsFromDi()),
        provideAnimations(),
      ],
    });
  });
