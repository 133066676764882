import { Injectable } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import { twoFactorAuthInterruptedAction } from '../actions/auth.actions';
import { LoginDialogComponent } from '../components/dialogs/login-dialog/login-dialog.component';
import { PasswordRecoveryDialogComponent } from '../components/dialogs/password-recovery-dialog/password-recovery-dialog.component';
import { SignupDialogComponent } from '../components/dialogs/signup-dialog/signup-dialog.component';
import { DIALOG_CONFIG_WITHOUT_AUTOFOCUS_GREY_BG } from '../dialogs-utils';
import { LoginDialogCloseReason } from '../dialogs/enums/login-dialog-close-reason.enum';
import { SignUpDialogCloseReason } from '../dialogs/enums/signup-dialog-close-reason.enum';
import { LoginDialogCloseDataModel } from '../dialogs/models/login-dialog-close-data.model';

/**
 * This is used to handle actions on dialog close
 */
@Injectable({
  providedIn: 'root',
})
export class DialogService {
  loginHref: MatDialogRef<LoginDialogComponent, LoginDialogCloseDataModel>;
  signupHref: MatDialogRef<SignupDialogComponent, SignUpDialogCloseReason>;

  constructor(
    private dialog: MatDialog,
    private store: Store,
  ) {}

  openLogin(): MatDialogRef<LoginDialogComponent, LoginDialogCloseDataModel> {
    this.loginHref = this.dialog.open(LoginDialogComponent, DIALOG_CONFIG_WITHOUT_AUTOFOCUS_GREY_BG);

    this.loginHref.afterClosed().subscribe((data) => {
      if (!data || !data.reason) {
        return;
      }

      const { reason, twoFADialogShown } = data;

      if (reason === LoginDialogCloseReason.PASSWORD_RECOVERY) {
        this.dialog.open(PasswordRecoveryDialogComponent, DIALOG_CONFIG_WITHOUT_AUTOFOCUS_GREY_BG);
      }
      if (reason === LoginDialogCloseReason.USER_CLOSE && twoFADialogShown) {
        this.store.dispatch(twoFactorAuthInterruptedAction());
      }
      if (reason === LoginDialogCloseReason.SIGN_UP) {
        this.openSignup();
      }
    });

    return this.loginHref;
  }

  openSignup(): MatDialogRef<SignupDialogComponent, SignUpDialogCloseReason> {
    this.signupHref = this.dialog.open(SignupDialogComponent, DIALOG_CONFIG_WITHOUT_AUTOFOCUS_GREY_BG);
    this.signupHref.afterClosed().subscribe((reason) => {
      if (!reason) {
        return;
      }
      if (reason === SignUpDialogCloseReason.LOGIN) {
        this.openLogin();
      }
    });
    return this.signupHref;
  }
}
