import { createReducer, on } from '@ngrx/store';
import {
  changeEmailByAdminAction,
  changeEmailByAdminErrorAction,
  changeEmailByAdminSuccessAction,
  clearUserDetailStateAction,
  getUserDetailAction,
  getUserDetailErrorAction,
  getUserDetailSuccessAction,
} from '../actions/user-detail.actions';
import { UserModel } from '../models/api/user.model';
import { StateModel } from '../models/auxiliary/state.model';

export type UserDetailState = StateModel<UserModel>;

const INITIAL_STATE: UserDetailState = {
  errors: null,
  data: null,
};

export const userDetailReducer = createReducer(
  INITIAL_STATE,
  on(clearUserDetailStateAction, (state) => ({
    ...INITIAL_STATE,
  })),
  on(getUserDetailAction, (state) => ({
    ...state,
    errors: null,
  })),
  on(getUserDetailSuccessAction, (state, { user }) => ({
    ...state,
    data: user,
  })),
  on(getUserDetailErrorAction, (state, { errors }) => ({
    ...state,
    errors: errors,
  })),
  on(changeEmailByAdminAction, (state, { email, userId }) => ({
    ...state,
    email: email,
    userId: userId,
    errors: null,
  })),
  on(changeEmailByAdminSuccessAction, (state, { user }) => ({
    ...state,
    data: user,
  })),
  on(changeEmailByAdminErrorAction, (state, { errors }) => ({
    ...state,
    errors: errors,
  })),
);
