import { NgClass, NgIf } from '@angular/common';
import { Component, Input } from '@angular/core';

@Component({
  selector: 'bp-landing-dialog-title',
  templateUrl: './landing-dialog-title.component.html',
  styleUrls: ['./landing-dialog-title.component.scss'],
  standalone: true,
  imports: [NgIf, NgClass],
})
export class LandingDialogTitleComponent {
  @Input() dialogTitle!: string;
  @Input() titleImage?: 'lock' | 'send';
}
