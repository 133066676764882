import { Injectable } from '@angular/core';
import { GA4CustomEventName, GA4PublicInvoiceCustomEventParams } from './ga4-custom-event.model';

import ControlParams = Gtag.ControlParams;
import EventParams = Gtag.EventParams;
import CustomParams = Gtag.CustomParams;
import EventNames = Gtag.EventNames;

type GtagEventParams = ControlParams | EventParams | CustomParams;

@Injectable({
  providedIn: 'root',
})
export class GoogleTagManagerService {
  private dataLayerExists(): boolean {
    // we should always check if dataLayer exists to avoid potential runtime errors (dataLayer script should be provided in index.html)
    return typeof window !== 'undefined' && window.dataLayer != null;
  }

  trackEvent(name: EventNames | GA4CustomEventName, params?: GtagEventParams): void {
    if (this.dataLayerExists()) {
      window.dataLayer.push({
        event: name,
        ...(params ?? {}),
        _clear: true,
      });
    }
  }

  trackPublicInvoiceEvent(name: GA4CustomEventName, params: GA4PublicInvoiceCustomEventParams): void {
    this.trackEvent(name, params);
  }
}
