import { createAction, props } from '@ngrx/store';
import { DotfileCaseAdminTo, DotfileCaseTo } from '../models/api/dotfile-case.model';
import { ErrorModel } from '../models/api/error.model';
import { BasicInfoUnionModel } from '../models/api/merchant-verification/basic-info-union.model';

export enum MerchantVerificationActionTypes {
  /** Admin actions */
  GetMerchantDotfileCaseAsAdmin = 'merchantVerification/GET_MERCHANT_DOTFILE_CASE_AS_ADMIN_REQUEST',
  GetMerchantDotfileCaseAsAdminSuccess = 'merchantVerification/GET_MERCHANT_DOTFILE_CASE_AS_ADMIN_SUCCESS',
  GetMerchantDotfileCaseAsAdminError = 'merchantVerification/CREATE_MERCHANT_DOTFILE_CASE_AS_ADMIN_ERROR',
  CreateDotfileCaseAsAdmin = 'merchantVerification/CREATE_DOTFILE_CASE_AS_ADMIN_REQUEST',
  CreateDotfileCaseAsAdminSuccess = 'merchantVerification/CREATE_DOTFILE_CASE_AS_ADMIN_SUCCESS',
  CreateDotfileCaseAsAdminError = 'merchantVerification/CREATE_DOTFILE_CASE_AS_ADMIN_ERROR',
  /** Merchant actions */
  GetDotfileCaseAsMerchant = 'merchantVerification/GET_DOTFILE_CASE_AS_MERCHANT_REQUEST',
  GetDotfileCaseAsMerchantSuccess = 'merchantVerification/GET_DOTFILE_CASE_AS_MERCHANT_SUCCESS',
  GetDotfileCaseAsMerchantError = 'merchantVerification/GET_DOTFILE_CASE_AS_MERCHANT_ERROR',
  GetVerificationBasicInfoAsMerchant = 'merchantVerification/GET_VERIFICATION_BASIC_INFO_AS_MERCHANT_REQUEST',
  GetVerificationBasicInfoAsMerchantSuccess = 'merchantVerification/GET_VERIFICATION_BASIC_INFO_AS_MERCHANT_SUCCESS',
  GetVerificationBasicInfoAsMerchantError = 'merchantVerification/GET_VERIFICATION_BASIC_INFO_AS_MERCHANT_ERROR',
  PostVerificationBasicInfoAsMerchant = 'merchantVerification/POST_VERIFICATION_BASIC_INFO_AS_MERCHANT_REQUEST',
  PostVerificationBasicInfoAsMerchantSuccess = 'merchantVerification/POST_VERIFICATION_BASIC_INFO_AS_MERCHANT_SUCCESS',
  PostVerificationBasicInfoAsMerchantError = 'merchantVerification/POST_VERIFICATION_BASIC_INFO_AS_MERCHANT_ERROR',
  GetDotfileClientPortalLink = 'merchantVerification/GET_DOTFILE_CLIENT_PORTAL_LINK_REQUEST',
  GetDotfileClientPortalLinkSuccess = 'merchantVerification/GET_DOTFILE_CLIENT_PORTAL_LINK_SUCCESS',
  GetDotfileClientPortalLinkError = 'merchantVerification/GET_DOTFILE_CLIENT_PORTAL_LINK_ERROR',
  ScheduleDotfileClientPortalLinkRefresh = 'merchantVerification/SCHEDULE_DOTFILE_CLIENT_PORTAL_LINK_REFRESH',
}

/**
 * Admin actions
 * */
export const getMerchantDotfileCaseAsAdminAction = createAction(
  MerchantVerificationActionTypes.GetMerchantDotfileCaseAsAdmin,
  props<{ merchantId: string }>(),
);

export const getMerchantDotfileCaseAsAdminSuccessAction = createAction(
  MerchantVerificationActionTypes.GetMerchantDotfileCaseAsAdminSuccess,
  props<{ dotfileCase: DotfileCaseAdminTo }>(),
);

export const getMerchantDotfileCaseAsAdminErrorAction = createAction(
  MerchantVerificationActionTypes.GetMerchantDotfileCaseAsAdminError,
  props<{ errors: ErrorModel[] }>(),
);

export const createDotfileCaseAsAdminAction = createAction(
  MerchantVerificationActionTypes.CreateDotfileCaseAsAdmin,
  props<{ merchantId: string }>(),
);

export const createDotfileCaseAsAdminSuccessAction = createAction(
  MerchantVerificationActionTypes.CreateDotfileCaseAsAdminSuccess,
);

export const createDotfileCaseAsAdminErrorAction = createAction(
  MerchantVerificationActionTypes.CreateDotfileCaseAsAdminError,
  props<{ errors: ErrorModel[] }>(),
);

/**
 * Merchant actions
 * */
export const getDotfileCaseAsMerchantAction = createAction(MerchantVerificationActionTypes.GetDotfileCaseAsMerchant);

export const getDotfileCaseAsMerchantSuccessAction = createAction(
  MerchantVerificationActionTypes.GetDotfileCaseAsMerchantSuccess,
  props<{ dotfileCase: DotfileCaseTo | null }>(),
);

export const getDotfileCaseAsMerchantErrorAction = createAction(
  MerchantVerificationActionTypes.GetDotfileCaseAsMerchantError,
  props<{ errors: ErrorModel[] }>(),
);

export const getVerificationBasicInfoAsMerchantAction = createAction(
  MerchantVerificationActionTypes.GetVerificationBasicInfoAsMerchant,
);

export const getVerificationBasicInfoAsMerchantSuccessAction = createAction(
  MerchantVerificationActionTypes.GetVerificationBasicInfoAsMerchantSuccess,
  props<{ basicInfo: BasicInfoUnionModel | null }>(),
);

export const getVerificationBasicInfoAsMerchantErrorAction = createAction(
  MerchantVerificationActionTypes.GetVerificationBasicInfoAsMerchantError,
  props<{ errors: ErrorModel[] }>(),
);

export const postVerificationBasicInfoAsMerchantAction = createAction(
  MerchantVerificationActionTypes.PostVerificationBasicInfoAsMerchant,
  props<{ payload: BasicInfoUnionModel }>(),
);

export const postVerificationBasicInfoAsMerchantSuccessAction = createAction(
  MerchantVerificationActionTypes.PostVerificationBasicInfoAsMerchantSuccess,
  props<{ basicInfo: BasicInfoUnionModel }>(),
);

export const postVerificationBasicInfoAsMerchantErrorAction = createAction(
  MerchantVerificationActionTypes.PostVerificationBasicInfoAsMerchantError,
  props<{ errors: ErrorModel[] }>(),
);

export const getDotfileClientPortalLinkAction = createAction(
  MerchantVerificationActionTypes.GetDotfileClientPortalLink,
);

export const getDotfileClientPortalLinkSuccessAction = createAction(
  MerchantVerificationActionTypes.GetDotfileClientPortalLinkSuccess,
  props<{ link: string; expires: string } | null>(),
);

export const getDotfileClientPortalLinkErrorAction = createAction(
  MerchantVerificationActionTypes.GetDotfileClientPortalLinkError,
  props<{ errors: ErrorModel[] }>(),
);

export const scheduleDotfileClientPortalLinkRefreshAction = createAction(
  MerchantVerificationActionTypes.ScheduleDotfileClientPortalLinkRefresh,
  props<{ expirationTime: number }>(),
);
