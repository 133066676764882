import { NgFor, NgIf } from '@angular/common';
import { Component } from '@angular/core';
import { FormsModule, ReactiveFormsModule, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { Actions, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { takeUntil } from 'rxjs/operators';
import {
  sendResetLinkAction,
  sendResetLinkErrorAction,
  sendResetLinkSuccessAction,
} from '../../../actions/login-prefill.actions';
import { closeDialogWithAnimation } from '../../../dialogs-utils';
import { AppStateModel } from '../../../models/auxiliary/app-state.model';
import { EmailRecoveryService } from '../../../services/email-recovery.service';
import { emailValidator } from '../../../validators/email.validator';
import { AbstractComponent } from '../../abstract.component';
import { InputLogoComponent } from '../../input-logo/input-logo.component';
import { LandingDialogButtonComponent } from '../../landing-button/landing-dialog-button.component';
import { LandingDialogTitleComponent } from '../landing-dialog-title/landing-dialog-title.component';
import { LandingDialogComponent } from '../landing-dialog/landing-dialog.component';

@Component({
  selector: 'bp-password-recovery-dialog',
  templateUrl: './password-recovery-dialog.component.html',
  styleUrls: ['./password-recovery-dialog.component.scss'],
  standalone: true,
  imports: [
    LandingDialogComponent,
    NgIf,
    LandingDialogTitleComponent,
    FormsModule,
    ReactiveFormsModule,
    InputLogoComponent,
    NgFor,
    LandingDialogButtonComponent,
  ],
})
export class PasswordRecoveryDialogComponent extends AbstractComponent {
  recoveryForm: UntypedFormGroup;
  apiErrors: string[] = [];
  emailSentTo: string;
  submitButtonDisabled: boolean;
  readonly errorMap = new Map<string, string>([
    ['email', 'Invalid e-mail address'],
    ['required', 'E-mail address required'],
  ]);

  constructor(
    private fb: UntypedFormBuilder,
    private store: Store<AppStateModel>,
    private actions: Actions,
    service: EmailRecoveryService,
    public dialogRef: MatDialogRef<PasswordRecoveryDialogComponent>,
  ) {
    super();

    this.recoveryForm = this.fb.group({
      email: [service.getEmail(), [Validators.required, emailValidator]],
    });

    this.actions.pipe(ofType(sendResetLinkSuccessAction), takeUntil(this.unsubscribe$)).subscribe(() => {
      this.apiErrors = [];
      this.emailSentTo = this.recoveryForm.get('email').value;
      this.recoveryForm.disable();
    });

    this.actions.pipe(ofType(sendResetLinkErrorAction), takeUntil(this.unsubscribe$)).subscribe(({ errors }) => {
      this.recoveryForm.enable();
      this.submitButtonDisabled = false;
      this.apiErrors = errors.map((error) => error.message);
    });
  }

  get emailInvalid(): boolean {
    const emailControl = this.recoveryForm.get('email');
    return emailControl.value !== '' && emailControl.errors !== null && emailControl.errors.pattern;
  }

  get emailNotEntered(): boolean {
    const emailControl = this.recoveryForm.get('email');
    return emailControl.dirty && emailControl.errors !== null && emailControl.errors.required;
  }

  resetPassword(): void {
    this.submitButtonDisabled = true;
    this.store.dispatch(sendResetLinkAction({ body: this.recoveryForm.value }));
    if (!this.apiErrors) {
      this.closeDialog();
    }
  }

  closeDialog(): void {
    closeDialogWithAnimation(this.dialogRef);
  }
}
