import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivateChild, Router, RouterStateSnapshot } from '@angular/router';
import { Observable, of as observableOf } from 'rxjs';
import { mergeMap } from 'rxjs/operators';
import { LandingPath } from '../enums/paths/landing-path.enum';
import { AuthService } from '../services/authorization/auth.service';

const DEFAULT_USER_PAGE = '/platform/dashboard';
const DEFAULT_ADMIN_PAGE = 'platform/admin';

@Injectable({
  providedIn: 'root',
})
export class PlatformGuard implements CanActivateChild {
  constructor(
    private authService: AuthService,
    private router: Router,
  ) {}

  canActivateChild(activatedRoute: ActivatedRouteSnapshot, routerState: RouterStateSnapshot): Observable<boolean> {
    return this.authService.invalidTokenUsed().pipe(
      mergeMap((invalidTokenUsed) => {
        if (invalidTokenUsed) {
          return observableOf(false);
        } else {
          return this.authService.isUserAuthorized().pipe(
            mergeMap((authorized) => {
              if (!authorized) {
                this.router.navigateByUrl(LandingPath.Home);
                return observableOf(false);
              } else if (routerState.url === '/platform') {
                this.navigateToDefaultPage();
              } else return observableOf(true);
            }),
          );
        }
      }),
    );
  }

  private navigateToDefaultPage(): void {
    this.authService.isUserAdmin().subscribe((isAdmin) => {
      this.router.navigateByUrl(isAdmin ? DEFAULT_ADMIN_PAGE : DEFAULT_USER_PAGE);
    });
  }
}
