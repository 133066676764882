import { NgIf } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormsModule, ReactiveFormsModule, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatIcon } from '@angular/material/icon';
import { Actions, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { takeUntil } from 'rxjs/operators';
import {
  sendVerificationLinkToEmail,
  sendVerificationLinkToEmailError,
  sendVerificationLinkToEmailSuccess,
} from '../../../../../shared/actions/merchant-verification-idenfy.actions';
import { AbstractComponent } from '../../../../../shared/components/abstract.component';
import { DIALOG_CONFIG_WITHOUT_AUTOFOCUS } from '../../../../../shared/dialogs-utils';
import { IdcheckVerificationDataModel } from '../../../../../shared/models/api/merchant-profile/idcheck-data.model';
import { AppStateModel } from '../../../../../shared/models/auxiliary/app-state.model';
import { selectIdcheckVerificationData } from '../../../../../shared/selectors/merchant-verification-idenfy.selector';
import { BrowserInfoService } from '../../../../../shared/services/browser-info.service';
import { MessageService } from '../../../../../shared/services/message.service';
import { generateQrCodeDataUrl } from '../../../../../shared/utils';
import { emailValidator } from '../../../../../shared/validators/email.validator';
import { IdenfyIframeDialogComponent } from '../idenfy-iframe/idenfy-iframe-dialog.component';
import { IdenfyMethodTypes } from '../idenfy.enum';

const IDENFY_LINK_BASE = 'https://ivs.idenfy.com/api/v2/redirect?authToken=';

@Component({
  selector: 'bp-idenfy-qr-code',
  templateUrl: './idenfy-qr-code.component.html',
  styleUrls: ['./idenfy-qr-code.component.scss', '../../aml-wizard.scss'],
  standalone: true,
  imports: [MatIcon, NgIf, FormsModule, ReactiveFormsModule],
})
export class IdenfyQrCodeComponent extends AbstractComponent {
  @Output() showMethodOffer = new EventEmitter<boolean>();
  @Input() nameToDisplay: string;
  @Input() merchantId: string;
  methodType: IdenfyMethodTypes;
  verificationData: IdcheckVerificationDataModel;
  qrCodeBrowserImg: string;
  qrCodeMobileImg: string;
  qrCodeBrowserString: string;
  qrCodeMobileString: string;
  emailForm: UntypedFormGroup;

  /**
   * for expiryTime default value is used, see:
   * {@link https://documentation.idenfy.com/API/GeneratingIdentificationToken}
   * sessionLength is set at 15 minutes
   */
  readonly defaultExpiryTime = '1 hour';
  readonly defaultSessionLength = '15 minutes';

  constructor(
    private fb: UntypedFormBuilder,
    private store: Store<AppStateModel>,
    private actions: Actions,
    private messageService: MessageService,
    private browserInfoService: BrowserInfoService,
    private dialog: MatDialog,
  ) {
    super();

    this.emailForm = this.fb.group({
      email: ['', [Validators.required, emailValidator]],
    });

    this.store
      .select(selectIdcheckVerificationData)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((state) => {
        if (state.errors !== null) {
          this.messageService.showErrors(state.errors, 'ID Check Data Error');
          return;
        }

        if (state.data) {
          this.verificationData = state.data;

          if (this.verificationData.digitString) {
            this.methodType = IdenfyMethodTypes.APP;
            this.qrCodeMobileString = this.verificationData.digitString;
            generateQrCodeDataUrl(this.qrCodeMobileString, (qrCodeUrl) => (this.qrCodeMobileImg = qrCodeUrl));
          } else {
            this.methodType = IdenfyMethodTypes.BROWSER;
            if (this.browserInfoService.isMobileOrTablet()) {
              window.open(`${IDENFY_LINK_BASE}${this.verificationData.authToken}`, '_blank');
            }
            this.qrCodeBrowserString = `${IDENFY_LINK_BASE}${this.verificationData.authToken}`;
            generateQrCodeDataUrl(this.qrCodeBrowserString, (qrCodeUrl) => (this.qrCodeBrowserImg = qrCodeUrl));
          }
        }
      });

    this.actions.pipe(ofType(sendVerificationLinkToEmailSuccess), takeUntil(this.unsubscribe$)).subscribe(() => {
      this.messageService.success('Please check your e-mail.');
    });

    this.actions
      .pipe(ofType(sendVerificationLinkToEmailError), takeUntil(this.unsubscribe$))
      .subscribe(({ errors }) => {
        this.messageService.showErrors(errors, "E-mail couldn't be sent.");
      });
  }

  stepBack(): void {
    this.showMethodOffer.emit(true);
  }

  sendLinkToEmail(): void {
    this.store.dispatch(
      sendVerificationLinkToEmail({
        merchantId: this.merchantId,
        email: this.emailForm.value,
      }),
    );
  }

  openDialog(): void {
    this.dialog.open(IdenfyIframeDialogComponent, {
      ...DIALOG_CONFIG_WITHOUT_AUTOFOCUS,
      data: { authToken: this.verificationData.authToken, locale: this.verificationData.verificationLocale },
    });
  }
}
