import { createReducer, on } from '@ngrx/store';
import { getMerchantAction, getMerchantErrorAction, getMerchantSuccessAction } from '../actions/merchant.actions';
import { MerchantModel } from '../models/api/merchant.model';
import { StateModel } from '../models/auxiliary/state.model';

export type MerchantState = StateModel<MerchantModel>;

const INITIAL_STATE: MerchantState = {
  errors: null,
  data: null,
  loading: false,
};

export const merchantReducer = createReducer(
  INITIAL_STATE,
  on(getMerchantAction, () => ({
    ...INITIAL_STATE,
    loading: true,
  })),
  on(getMerchantSuccessAction, (state, { merchant }) => ({
    errors: null,
    data: merchant,
    loading: false,
  })),
  on(getMerchantErrorAction, (state, { errors }) => ({
    errors: errors,
    loading: false,
  })),
);
