export enum LandingPath {
  Home = 'home',
  Product = 'product',
  Pricing = 'pricing',
  Industry = 'industry',
  SupportedCoins = 'supported-coins',
  Legal = 'legal',
  Login = 'login',
  Signup = 'signup',
  AboutUs = 'about-us',
  FAQ = 'faq',
  NotFound = 'not-found',
  Campaign = 'campaign',
}
