export enum CryptoAssetCode {
  BTC = 'BTC',
  LTC = 'LTC',
  ETH = 'ETH',
  USDT = 'USDT',
  USDC = 'USDC',
  SOL = 'SOL',
  TRX = 'TRX',
  POL = 'POL',
}
