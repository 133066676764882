<div class="landing-input-wrapper">
  <div
    class="landing-input-container"
    (click)="focusInput()"
    [ngClass]="{ 'landing-input-container__disabled': ngControl.disabled }"
  >
    <div class="landing-input-icon-container">
      <span
        *ngIf="icon"
        class="landing-input-icon"
        [className]="icon"
        [ngClass]="{ invalid: shouldShowInvalid }"
      ></span>
    </div>
    <input
      #input
      class="landing-input"
      [ngClass]="{ 'landing-input-error-color': shouldShowInvalid }"
      [name]="name"
      [autocomplete]="autocomplete"
      [type]="type"
      [placeholder]="placeholder"
      [maxLength]="maxLength"
      (input)="onInput()"
      (blur)="onBlur()"
      (focusin)="onFocusIn()"
      (focusout)="onFocusOut()"
      [(ngModel)]="value"
    />
    <ul class="landing-input-errors" *ngIf="shouldShowInvalid">
      <li *ngFor="let i of errors" class="landing-input-error">{{ i }}</li>
    </ul>
  </div>
  <bp-tooltip
    [snapTo]="input"
    [shown]="!!showRequirements"
    [style]="'white'"
    [positioning]="tooltipPosition"
    [landingTooltip]="true"
  >
    <bp-password-requirements [passwordControl]="ngControl" [isLanding]="true"></bp-password-requirements>
  </bp-tooltip>
</div>
