import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of as observableOf } from 'rxjs';
import { catchError, map, mergeMap, switchMap } from 'rxjs/operators';
import {
  getPreparedCurrenciesAction,
  getPreparedCurrenciesErrorAction,
  getPreparedCurrenciesSuccessAction,
  updatePreparedInvoiceCurrencyAction,
  updatePreparedInvoiceCurrencyErrorAction,
  updatePreparedInvoiceCurrencySuccessAction,
} from '../actions/invoice-prepared.action';
import {
  confirmInvoiceTakeAction,
  confirmInvoiceTakeErrorAction,
  confirmInvoiceTakeOverpaymentAction,
  confirmInvoiceTakeOverpaymentErrorAction,
  confirmInvoiceTakeOverpaymentSuccessAction,
  confirmInvoiceTakeSuccessAction,
  createInvoiceAction,
  createInvoiceErrorAction,
  createInvoiceFromButtonAction,
  createInvoiceFromButtonErrorAction,
  createInvoiceFromButtonSuccessAction,
  createInvoiceFromEmailInvoiceAction,
  createInvoiceFromEmailInvoiceErrorAction,
  createInvoiceFromEmailInvoiceSuccessAction,
  createInvoiceManualTransactionAction,
  createInvoiceManualTransactionErrorAction,
  createInvoiceManualTransactionSuccessAction,
  createInvoiceSuccessAction,
  createInvoiceTakeAction,
  createInvoiceTakeErrorAction,
  createInvoiceTakeOverpaymentAction,
  createInvoiceTakeOverpaymentErrorAction,
  createInvoiceTakeOverpaymentSuccessAction,
  createInvoiceTakeSuccessAction,
  getInvoiceAction,
  getInvoiceErrorAction,
  getInvoiceSuccessAction,
  markTransactionRefundableAction,
  markTransactionRefundableErrorAction,
  markTransactionRefundableSuccessAction,
  setNullAmountInvoiceAction,
  setNullAmountInvoiceErrorAction,
  setNullAmountInvoiceSuccessAction,
  updateInvoiceStatusAction,
  updateInvoiceStatusErrorAction,
  updateInvoiceStatusSuccessAction,
} from '../actions/invoice.actions';
import { ADMIN_ENDPOINT_PREFIX, API_VERSION } from '../constants';
import { ApiService } from '../services/api.service';
import { queryParams } from '../utils';
import { EMAIL_INVOICE_ENDPOINT } from './email-invoice.effect';

export const INVOICE_ENDPOINT = `invoices`;
export const INVOICE_EXPORT_ENDPOINT = `${INVOICE_ENDPOINT}/export`;
export const PREPARED_CURRENCIES = 'prices';

@Injectable()
export class InvoiceEffect {
  get$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getInvoiceAction),
      mergeMap(({ invoiceId, isAdmin }) =>
        this.api.get(`${this.url(isAdmin)}/${invoiceId}`).pipe(
          map((invoice) => getInvoiceSuccessAction({ invoice })),
          catchError((errors) => observableOf(getInvoiceErrorAction(errors))),
        ),
      ),
    ),
  );

  getPreparedCurrencies$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getPreparedCurrenciesAction),
      mergeMap(({ invoiceHashId }) =>
        this.api.get(`/${INVOICE_ENDPOINT}/${invoiceHashId}/${PREPARED_CURRENCIES}`).pipe(
          map((currencies) => getPreparedCurrenciesSuccessAction({ currencies })),
          catchError((errors) => observableOf(getPreparedCurrenciesErrorAction(errors))),
        ),
      ),
    ),
  );

  createInvoice$ = createEffect(() =>
    this.actions$.pipe(
      ofType(createInvoiceAction),
      switchMap(({ invoice }) =>
        this.api.post(this.url(), invoice).pipe(
          map((invoice) => createInvoiceSuccessAction({ invoice })),
          catchError((errors) => observableOf(createInvoiceErrorAction(errors))),
        ),
      ),
    ),
  );

  updateInvoiceStatus$ = createEffect(() =>
    this.actions$.pipe(
      ofType(updateInvoiceStatusAction),
      switchMap(({ invoiceId, invoiceStatusRequest }) =>
        this.api.patch(`/${ADMIN_ENDPOINT_PREFIX}/${INVOICE_ENDPOINT}/${invoiceId}/status`, invoiceStatusRequest).pipe(
          map((response) => updateInvoiceStatusSuccessAction()),
          catchError((errors) => observableOf(updateInvoiceStatusErrorAction(errors))),
        ),
      ),
    ),
  );

  updateInvoicePreparedStatus$ = createEffect(() =>
    this.actions$.pipe(
      ofType(updatePreparedInvoiceCurrencyAction),
      switchMap(({ invoiceId, selectedPaymentMethod }) =>
        this.api.patch(`/${INVOICE_ENDPOINT}/${invoiceId}/currency`, selectedPaymentMethod).pipe(
          map((response) => updatePreparedInvoiceCurrencySuccessAction()),
          catchError((errors) => observableOf(updatePreparedInvoiceCurrencyErrorAction(errors))),
        ),
      ),
    ),
  );

  createManualTransaction$ = createEffect(() =>
    this.actions$.pipe(
      ofType(createInvoiceManualTransactionAction),
      switchMap(({ manualTransaction }) =>
        this.api
          .post(
            `/${ADMIN_ENDPOINT_PREFIX}/${INVOICE_ENDPOINT}/${manualTransaction.invoiceId}/transactions`,
            manualTransaction.body,
          )
          .pipe(
            map((response) => createInvoiceManualTransactionSuccessAction()),
            catchError((errors) => observableOf(createInvoiceManualTransactionErrorAction(errors))),
          ),
      ),
    ),
  );

  createInvoiceTakeRequest$ = createEffect(() =>
    this.actions$.pipe(
      ofType(createInvoiceTakeAction),
      switchMap(({ invoiceId }) =>
        this.api.post(`${this.url()}/${invoiceId}/take`).pipe(
          map((takeInfo) => createInvoiceTakeSuccessAction({ takeInfo })),
          catchError((errors) => observableOf(createInvoiceTakeErrorAction(errors))),
        ),
      ),
    ),
  );

  createInvoiceTakeRequestOverpayment$ = createEffect(() =>
    this.actions$.pipe(
      ofType(createInvoiceTakeOverpaymentAction),
      switchMap(({ invoiceId }) =>
        this.api.post(`${this.urlOverpayment()}/${invoiceId}/take/overpayment`).pipe(
          map((invoiceOverpayment) => createInvoiceTakeOverpaymentSuccessAction({ invoiceOverpayment })),
          catchError((errors) => observableOf(createInvoiceTakeOverpaymentErrorAction(errors))),
        ),
      ),
    ),
  );

  createInvoiceTakeConfirm$ = createEffect(() =>
    this.actions$.pipe(
      ofType(confirmInvoiceTakeAction),
      switchMap(({ invoiceId, skipWebhook }) =>
        this.api
          .post(`${this.url()}/${invoiceId}/take-confirm`, {
            skipWebhook: skipWebhook,
          })
          .pipe(
            map((takeInfo) => confirmInvoiceTakeSuccessAction({ takeInfo })),
            catchError((errors) => observableOf(confirmInvoiceTakeErrorAction(errors))),
          ),
      ),
    ),
  );

  createInvoiceTakeConfirmOverpayment$ = createEffect(() =>
    this.actions$.pipe(
      ofType(confirmInvoiceTakeOverpaymentAction),
      switchMap(({ invoiceId }) =>
        this.api.post(`${this.urlOverpayment()}/${invoiceId}/take-confirm/overpayment`).pipe(
          map((invoiceOverpayment) => confirmInvoiceTakeOverpaymentSuccessAction({ invoiceOverpayment })),
          catchError((errors) => observableOf(confirmInvoiceTakeOverpaymentErrorAction(errors))),
        ),
      ),
    ),
  );

  createInvoiceFromEmailInvoice$ = createEffect(() =>
    this.actions$.pipe(
      ofType(createInvoiceFromEmailInvoiceAction),
      switchMap(({ emailInvoiceId }) =>
        this.api.post(EMAIL_INVOICE_ENDPOINT.replace('{hash}', emailInvoiceId)).pipe(
          map((invoice) => createInvoiceFromEmailInvoiceSuccessAction({ invoice })),
          catchError((errors) => observableOf(createInvoiceFromEmailInvoiceErrorAction(errors))),
        ),
      ),
    ),
  );

  createInvoiceFromPaymentButton$ = createEffect(() =>
    this.actions$.pipe(
      ofType(createInvoiceFromButtonAction),
      switchMap(({ buttonId, params }) =>
        this.api.post(`/button/${buttonId}/invoices${queryParams(params)}`).pipe(
          map((invoice) => createInvoiceFromButtonSuccessAction({ invoice })),
          catchError((errors) => observableOf(createInvoiceFromButtonErrorAction(errors))),
        ),
      ),
    ),
  );

  markTransactionRefundable$ = createEffect(() =>
    this.actions$.pipe(
      ofType(markTransactionRefundableAction),
      switchMap(({ txid, address, paymentMethodCode }) =>
        this.api
          .patch(`/${ADMIN_ENDPOINT_PREFIX}/${INVOICE_ENDPOINT}/transactions`, {
            txid: txid,
            address: address,
            paymentMethodCode: paymentMethodCode,
          })
          .pipe(
            map((response) => markTransactionRefundableSuccessAction()),
            catchError((errors) => observableOf(markTransactionRefundableErrorAction(errors))),
          ),
      ),
    ),
  );

  setNullAmount$ = createEffect(() =>
    this.actions$.pipe(
      ofType(setNullAmountInvoiceAction),
      switchMap(({ txid, address }) =>
        this.api
          .patch(`/${ADMIN_ENDPOINT_PREFIX}/${INVOICE_ENDPOINT}/transaction/null-amount`, {
            txid: txid,
            address: address,
          })
          .pipe(
            map((response) => setNullAmountInvoiceSuccessAction()),
            catchError((errors) => observableOf(setNullAmountInvoiceErrorAction(errors))),
          ),
      ),
    ),
  );

  constructor(
    private actions$: Actions,
    private api: ApiService,
  ) {}

  url(isAdmin?: boolean): string {
    if (isAdmin) {
      return `/${ADMIN_ENDPOINT_PREFIX}/${INVOICE_ENDPOINT}`;
    }
    return `/${API_VERSION}/${INVOICE_ENDPOINT}`;
  }

  urlOverpayment(isAdmin?: boolean): string {
    if (isAdmin) {
      return `/${ADMIN_ENDPOINT_PREFIX}/${INVOICE_ENDPOINT}`;
    }
    return `/${INVOICE_ENDPOINT}`;
  }
}
