<bp-landing-dialog (closePressed)="closeDialog()">
  <div class="dialog-signup-content animate__appear-down">
    <ng-container *ngIf="registered; else registrationForm">
      <div class="signup-dialog-registered">
        <bp-landing-dialog-title dialogTitle="Check your e-mail" titleImage="lock"></bp-landing-dialog-title>
        <div class="signup-dialog-confirm-message">
          An e-mail containing activation link has been sent to
          <strong>{{ inputtedEmail }}</strong>
          <span>.</span>
          <br />
          <br />
          Please click the link to finish the registration.
        </div>
      </div>
    </ng-container>
    <ng-template #registrationForm>
      <div class="signup-dialog-register">
        <bp-landing-dialog-title dialogTitle="Sign up"></bp-landing-dialog-title>
        <form [formGroup]="registerForm" class="signup-dialog-form" (ngSubmit)="submitForm()" novalidate>
          <div class="dialog-input-container" *ngIf="isCountryVisible">
            <bp-country-select
              class="signup-dialog-country-select"
              [countries]="countries"
              [isDisabled]="countriesSelectDisabled"
              [(selected)]="selectedCountry"
              [height]="'high'"
              [redesigned]="true"
              placeholder="Nationality"
              warningMessage="Select your nationality"
            ></bp-country-select>
          </div>
          <div class="signup-dialog-email">
            <bp-input-logo
              icon="email"
              placeholder="E-mail"
              autocomplete="email"
              name="email"
              type="email"
              formControlName="email"
              [errorMap]="emailErrorMap"
            >
            </bp-input-logo>
          </div>
          <div class="signup-dialog-password">
            <bp-input-logo
              icon="lock"
              placeholder="Password"
              autocomplete="new-password"
              name="new-password"
              [type]="isPasswordVisible ? 'text' : 'password'"
              formControlName="password"
              [errorMap]="passwordErrorMap"
              [requirementMap]="passwordRequirementMap"
            >
            </bp-input-logo>
          </div>
          <div class="signup-dialog-phone-number">
            <bp-input-logo
              icon="phone"
              placeholder="Phone number (optional)"
              name="phoneNumber"
              type="text"
              formControlName="phoneNumber"
              [errorMap]="phoneNumberErrorMap"
            >
            </bp-input-logo>
          </div>
          <div class="signup-dialog-turnstile-widget" id="turnstile-container"></div>
          <div *ngFor="let error of apiErrors" class="dialog-error dialog-notes signup-dialog-errors">{{ error }}</div>
          <bp-landing-dialog-button
            type="submit"
            class="signup-dialog-button"
            [disabled]="signUpDisabled || !isCountryValid || registerForm.invalid || turnstileToken == null"
          >
            Sign up
          </bp-landing-dialog-button>
          <div class="signup-dialog-policy-warning">
            By creating an account, you confirm that you have read and agree to our
            <a routerLink="/legal/terms-and-conditions" class="landing-dialog-as-link" (click)="closeDialog()">
              Terms and Conditions
            </a>
            and
            <a routerLink="/legal/privacy-policy" class="landing-dialog-as-link" (click)="closeDialog()">
              Privacy Policy.
            </a>
          </div>
          <div class="signup-dialog-footer-text">
            Already have an account?
            <button class="landing-dialog-as-link" (click)="openLoginDialog()">Log in.</button>
          </div>
        </form>
      </div>
    </ng-template>
  </div>
</bp-landing-dialog>
