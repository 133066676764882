<div [ngClass]="{ 'landing-password-requirements': isLanding }">
  <div class="password-requirement">
    <div class="password-requirement-icon" [ngClass]="{ 'requirement-met': !passwordErrors?.passLength }">
      <span class="password-requirement-icon-check"></span>
    </div>
    <span class="password-requirement-text">at least {{ passwordMinLength }} characters</span>
  </div>
  <div class="password-requirement">
    <div class="password-requirement-icon" [ngClass]="{ 'requirement-met': !passwordErrors?.passUpper }">
      <span class="password-requirement-icon-check"></span>
    </div>
    <span class="password-requirement-text">contains uppercase letters</span>
  </div>
  <div class="password-requirement">
    <div class="password-requirement-icon" [ngClass]="{ 'requirement-met': !passwordErrors?.passLower }">
      <span class="password-requirement-icon-check"></span>
    </div>
    <span class="password-requirement-text">contains lowercase letters</span>
  </div>
  <div class="password-requirement">
    <div class="password-requirement-icon" [ngClass]="{ 'requirement-met': !passwordErrors?.passDigits }">
      <span class="password-requirement-icon-check"></span>
    </div>
    <span class="password-requirement-text">contains digits</span>
  </div>
</div>
