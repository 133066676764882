import { ApplicationConfig } from '@angular/core';
import { provideRouter, withDebugTracing, withInMemoryScrolling } from '@angular/router';
import { appRoutes } from './app.routes';

export const appConfig: ApplicationConfig = {
  providers: [
    provideRouter(appRoutes, {
      ...withDebugTracing(),
      ...withInMemoryScrolling({ scrollPositionRestoration: 'top' }),
    }),
  ],
};
