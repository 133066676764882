import { Injectable, inject } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of as observableOf } from 'rxjs';
import { catchError, map, mergeMap, switchMap, tap } from 'rxjs/operators';
import {
  changeEmailByAdminAction,
  changeEmailByAdminErrorAction,
  changeEmailByAdminSuccessAction,
  createUserAction,
  createUserAsAdminAction,
  createUserErrorAction,
  createUserSuccessAction,
  getUserDetailAction,
  getUserDetailErrorAction,
  getUserDetailSuccessAction,
} from '../actions/user-detail.actions';
import { ADMIN_ENDPOINT_PREFIX } from '../constants';
import { ApiService, get2FaHeaders } from '../services/api.service';
import { MessageService } from '../services/message.service';
import { MERCHANT_USER_LIST_ENDPOINT } from '../store/user-list/user-list.effects';

const USER_CREATE_ADMIN_ENDPOINT = `/${ADMIN_ENDPOINT_PREFIX}/merchants/{merchantHashId}/users`;
const USER_EMAIL_CHANGE_ADMIN_ENDPOINT = `/${ADMIN_ENDPOINT_PREFIX}/users/{hashId}/email`;

@Injectable()
export class UserDetailEffect {
  private readonly actions$ = inject(Actions);
  private readonly api = inject(ApiService);
  private readonly messageService = inject(MessageService);

  get$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getUserDetailAction),
      mergeMap(({ id }) =>
        this.api.get(`${MERCHANT_USER_LIST_ENDPOINT}/${id}`).pipe(
          map((user) => getUserDetailSuccessAction({ user })),
          catchError((errors) => observableOf(getUserDetailErrorAction(errors))),
        ),
      ),
    ),
  );

  create$ = createEffect(() =>
    this.actions$.pipe(
      ofType(createUserAction),
      switchMap(({ user, twoFaCode }) =>
        this.api.post(`${MERCHANT_USER_LIST_ENDPOINT}`, user, get2FaHeaders(twoFaCode)).pipe(
          map((user) => createUserSuccessAction({ user })),
          catchError((errors) => observableOf(createUserErrorAction(errors))),
        ),
      ),
    ),
  );

  createAsAdmin$ = createEffect(() =>
    this.actions$.pipe(
      ofType(createUserAsAdminAction),
      switchMap(({ user, merchantId }) =>
        this.api.post(`${USER_CREATE_ADMIN_ENDPOINT.replace('{merchantHashId}', merchantId)}`, user).pipe(
          map((user) => createUserSuccessAction({ user })),
          catchError((errors) => observableOf(createUserErrorAction(errors))),
        ),
      ),
    ),
  );

  changeEmailByAdmin$ = createEffect(() =>
    this.actions$.pipe(
      ofType(changeEmailByAdminAction),
      switchMap(({ email, userId }) =>
        this.api.patch(`${USER_EMAIL_CHANGE_ADMIN_ENDPOINT.replace('{hashId}', userId)}`, email).pipe(
          map((user) => changeEmailByAdminSuccessAction({ user })),
          catchError((errors) => observableOf(changeEmailByAdminErrorAction(errors))),
        ),
      ),
    ),
  );

  showSuccessMessageOnChangeEmailByAdmin$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(changeEmailByAdminSuccessAction),
        tap(({ user }) => {
          this.messageService.success('Email successfully changed to ' + user.email);
        }),
      ),
    { dispatch: false },
  );

  showErrorMessageOnChangeEmailByAdmin$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(changeEmailByAdminErrorAction),
        tap(({ errors }) => {
          this.messageService.showErrors(errors, 'Email cannot be updated.');
        }),
      ),
    { dispatch: false },
  );
}
