import { createReducer, on } from '@ngrx/store';
import {
  invalidTokenUsedAction,
  loginAfterRegistrationAction,
  loginErrorAction,
  loginSuccessAction,
  loginTwoFANeededAction,
  logoutErrorAction,
  logoutSuccessAction,
  twoFactorAuthErrorAction,
  twoFactorAuthInterruptedAction,
} from '../actions/auth.actions';
import { AuthModel } from '../models/api/auth.model';
import { StateModel } from '../models/auxiliary/state.model';

export type AuthState = StateModel<AuthModel>;

const INITIAL_STATE: AuthState = {
  errors: null,
  data: null,
};

export const authReducer = createReducer(
  INITIAL_STATE,
  on(loginAfterRegistrationAction, loginSuccessAction, (state, { token }) => ({
    errors: null,
    data: {
      token: token,
      authorized: true,
      invalidToken: false,
    },
  })),
  on(loginTwoFANeededAction, (state, { twoFaRequiredType }) => ({
    errors: null,
    data: {
      token: null,
      authorized: false,
      twoFaRequiredType: twoFaRequiredType,
    },
  })),
  on(loginErrorAction, twoFactorAuthErrorAction, (state, { errors }) => ({
    errors: errors,
  })),
  on(twoFactorAuthInterruptedAction, logoutSuccessAction, (state) => ({
    ...INITIAL_STATE,
  })),
  on(logoutErrorAction, (state, { errors }) => ({
    ...state,
    errors: errors,
  })),
  on(invalidTokenUsedAction, (state) => ({
    ...state,
    data: {
      ...state.data,
      authorized: false,
      invalidToken: true,
    },
  })),
);
