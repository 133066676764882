export const DEMO_INVOICE_LINK =
  'https://confirmo.net/public/button-invoice/zpqLgm5rxyWklEObqBB2vP8D0oRwY6VaAjK?productName=Demo%20payment&productDescription=Demo%20product%20with%20accessories&reference=%231&returnUrl=https://confirmo.net';
export const POS_APP_STORE_URL = 'https://apps.apple.com/us/app/bitcoin-lightning-pos/id1639106148';
export const POS_GOOGLE_PLAY_URL = 'https://play.google.com/store/apps/details?id=cz.confirmo.app.confirmo_flutter';
export const WOO_COMMERCE_GITHUB_URL = 'https://github.com/confirmo/confirmo-woocommerce/releases';
export const AUTO_API_DOC_LINK = 'https://docs.confirmo.net/reference/intro-to-the-confirmo-api#/';
export const API_DOC_CREATE_INVOICE_LINK = 'https://docs.confirmo.net/reference/createinvoicebymerchant-1#/';
export const API_DOC_PAYOUTS_LINK = 'https://docs.confirmo.net/reference/preparebyapi-1#/';
export const API_DOC_CALLBACK_PASSWORD_LINK = 'https://docs.confirmo.net/reference/notifications#/callback-password';
export const ZERO_HEIGHT_LINK = 'https://zeroheight.com/60b759ccc';
export const ZERO_HEIGHT_OUR_STORY_LINK = `${ZERO_HEIGHT_LINK}/p/81807f-our-story`;
export const ZERO_HEIGHT_PAYMENT_BUTTON_LINK = `${ZERO_HEIGHT_LINK}/p/6289f5-payment-button`;
export const BLOG_LINK = 'https://confirmo.net/blog';
export const LINKEDIN_LINK = 'https://www.linkedin.com/company/5391370';
export const FACEBOOK_LINK = 'https://www.facebook.com/confirmo.net';
export const TWITTER_LINK = 'https://twitter.com/CryptoConfirmo';
export const GITHUB_LINK = 'https://github.com/confirmo';
export const SUPPORT_EMAIL = 'support@confirmo.net';
export const MAIL_TO_SUPPORT_EMAIL = 'mailto:' + SUPPORT_EMAIL;
export const WIKI_LINK = 'https://confirmo.net/wiki/';
export const WIKI_IMPLEMENTATION_LINK = 'https://confirmo.net/wiki/implementation/';
export const WIKI_WITHDRAWALS_LINK = 'https://confirmo.net/wiki/withdrawals/';
export const WIKI_KYC_LINK = 'https://confirmo.net/wiki/verification/';
export const BLOG_INVOICE_SETTINGS_LINK = 'https://confirmo.net/blog/invoice-settings-and-exceptions/';
export const CONFIRMO_LOGIN_LINK = 'https://confirmo.net/login';
export const MEDIA_LINK = 'https://confirmo.net/wiki/visual-guidelines/';
export const CAREER_LINK = 'https://confirmo.recruitee.com/';
export const LIGHTNING_NODE_LINK =
  'https://mempool.space/lightning/node/02ff30e83896d453cfc89ff4dd06d23d793b7246f154c210324adc1d42c849ce74';
export const FORBES_LINK =
  'https://www.forbes.com/advisor/business/software/best-cryptocurrency-payment-gateway/#confirmo_section';
export const G2_LINK = 'https://www.g2.com/products/confirmo/reviews';
export const PRODUCT_HUNT_LINK = 'https://www.producthunt.com/products/confirmo#confirmo';
export const CONTACT_SUPPORT_LINK = 'https://zfrmz.eu/pj9RPih8GnjVC3mHWiuh';
export const CONTACT_SUPPORT_POPUP_LINK = 'https://zfrmz.eu/V4g3Dd6jK1B7M1OPURwd';
export const BLOG_TO_DO_LINK = 'https://confirmo.net/blog/to-do/';
export const BITCOIN_NEWS_LINK =
  'https://news.bitcoin.com/largest-czech-online-retailer-alza-accepts-bitcoin-installs-2-bitcoin-atms-in-showrooms/';
export const COIN_TELEGRAPH_LINK =
  'https://cointelegraph.com/news/coinmate-partners-with-moneypolo-allowing-purchasing-and-selling-bitcoin-with-cash';
export const COIN_CODEX_LINK = 'https://coincodex.com/article/39025/best-crypto-payment-gateways/';
export const WIRED_LINK =
  'https://www.wired.cz/clanky/bitcoinu-je-jedno-co-si-na-nej-evropa-vymysli-je-pro-nej-prilis-mala';
export const BTC_PRAGUE_LINK = 'https://btcprague.com/';
export const ETH_PRAGUE_LINK = 'https://ethprague.com/';
export const BTC_MAP_LINK = 'https://btcmap.org/map#8/49.77062/15.83130';
export const IB_TIMES_LINK =
  'https://www.ibtimes.com/venmo-paypal-19-other-online-payment-apps-buying-selling-cryptocurrency-3184430';
