export enum UserRegistrationStatus {
  PASSWORD_NOT_SET = 'PASSWORD_NOT_SET',
  NOT_ACTIVATED = 'NOT_ACTIVATED',
}

export interface UserActivationModel {
  email: string;
  registrationStatus: UserRegistrationStatus;
  token?: string;
}
