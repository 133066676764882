import { NgClass, NgIf } from '@angular/common';
import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, Input, OnDestroy, ViewChild } from '@angular/core';
import { isElementOverflown } from '../../utils';

@Component({
  selector: 'bp-tooltip',
  templateUrl: './tooltip.component.html',
  styleUrls: ['./tooltip.component.scss'],
  standalone: true,
  imports: [NgClass, NgIf],
})
export class TooltipComponent implements AfterViewInit, OnDestroy {
  @ViewChild('tooltip', { static: true }) tooltipRoot: ElementRef = null;
  @Input() snapTo: HTMLElement = null;
  @Input() style: 'white' | 'black' = 'black';
  @Input() positioning: 'bottom' | 'right' | 'left' | 'table-top' | 'table-right' | 'landing-bottom' | 'table-bottom' =
    'right';
  @Input() showOnHover = false;
  @Input() showIfOverflown = false;
  @Input() leaveOpenAfterClick = false;
  @Input() maxWidth: number;
  @Input() landingTooltip = false;
  readonly tooltipArrowHeight = 16;
  readonly minimalOffsetTop = 5;
  readonly minimalOffsetLeft = 5;
  offsetLeft = 0;
  offsetTop = 0;
  arrowOffsetTop = 0;
  arrowOffsetLeft = 0;
  tooltipShown = true;

  constructor(private cdr: ChangeDetectorRef) {}

  @Input() set shown(tooltipShown: boolean) {
    this.showOrHideTooltip(tooltipShown);
  }

  private showOrHideTooltip(tooltipShown: boolean): void {
    this.tooltipShown = tooltipShown;
    this.tooltipRoot.nativeElement.style.opacity = tooltipShown ? 1 : 0;
    this.tooltipRoot.nativeElement.style.zIndex = tooltipShown ? 3 : -1;
    this.callRepositionByMode();
    this.cdr.detectChanges();
  }

  private hideTooltip = () => {
    return this.showOrHideTooltip(false);
  };

  private addMouseOutEventListener(): void {
    this.snapTo.addEventListener('mouseout', this.hideTooltip, true);
  }

  private removeMouseOutEventListener(): void {
    this.snapTo.removeEventListener('mouseout', this.hideTooltip, true);
  }

  /* puts mouseout event listener back to make the tooltip be autoclosable again */
  addMouseOutEventListenerAndClose(): void {
    this.showOrHideTooltip(false);
    this.addMouseOutEventListener();
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.callRepositionByMode();

      if (this.showOnHover) {
        this.snapTo.addEventListener('mouseover', () => {
          this.shown = !this.showIfOverflown || isElementOverflown(this.snapTo);
        });

        this.addMouseOutEventListener();

        if (this.leaveOpenAfterClick) {
          /* removes mouseout event listener, so a user has possibility to copy the tooltip
             content */
          this.snapTo.addEventListener('click', () => {
            this.removeMouseOutEventListener();
          });
        }
      }
    }, 0);
  }

  ngOnDestroy(): void {
    this.tooltipShown = false;
  }

  private repositionRight(): void {
    if (this.tooltipRoot !== null && this.snapTo !== null) {
      const tooltipRect = this.tooltipRoot.nativeElement.getBoundingClientRect();
      const snapRect = this.snapTo.getBoundingClientRect();
      this.offsetLeft = snapRect.right < snapRect.width ? snapRect.right : snapRect.left + snapRect.width;
      const offsetTopValue = snapRect.top + snapRect.height / 2 - tooltipRect.height / 1.8;
      this.offsetTop = offsetTopValue > 0 ? offsetTopValue : this.minimalOffsetTop;
      this.arrowOffsetTop =
        this.offsetTop === this.minimalOffsetTop
          ? snapRect.top + snapRect.height / 5
          : tooltipRect.height / 2 - this.tooltipArrowHeight / 2;
      this.arrowOffsetLeft = -this.tooltipArrowHeight / 2;
    }
    if (this.tooltipShown) {
      requestAnimationFrame(() => this.repositionRight());
    }
  }
  private repositionLeft(): void {
    if (this.tooltipRoot !== null && this.snapTo !== null) {
      const tooltipRect = this.tooltipRoot.nativeElement.getBoundingClientRect();
      const snapRect = this.snapTo.getBoundingClientRect();
      this.offsetLeft = snapRect.left - tooltipRect.width - 10;
      const offsetTopValue = snapRect.top + snapRect.height / 2 - tooltipRect.height / 2;
      this.offsetTop = offsetTopValue > 0 ? offsetTopValue : this.minimalOffsetTop;
      this.arrowOffsetTop =
        this.offsetTop === this.minimalOffsetTop
          ? snapRect.top + snapRect.height / 5
          : tooltipRect.height / 2 - this.tooltipArrowHeight / 2;
      this.arrowOffsetLeft = tooltipRect.width - this.tooltipArrowHeight / 2;
    }
    if (this.tooltipShown) {
      requestAnimationFrame(() => this.repositionLeft());
    }
  }

  private repositionBottom(): void {
    if (this.tooltipRoot !== null && this.snapTo !== null) {
      const tooltipRect = this.tooltipRoot.nativeElement.getBoundingClientRect();
      const snapRect = this.snapTo.getBoundingClientRect();
      this.offsetTop = snapRect.bottom < snapRect.height ? snapRect.bottom : snapRect.top + snapRect.height;
      const offsetLeftValue = snapRect.left + snapRect.width / 2 - tooltipRect.width / 2;
      this.offsetLeft = offsetLeftValue > 0 ? offsetLeftValue : this.minimalOffsetLeft;
      this.arrowOffsetLeft =
        this.offsetTop === this.minimalOffsetLeft
          ? snapRect.left + snapRect.width / 2
          : tooltipRect.width / 2 - this.tooltipArrowHeight / 2;
      this.arrowOffsetTop = -this.tooltipArrowHeight / 4;
    }
    if (this.tooltipShown) {
      requestAnimationFrame(() => this.repositionBottom());
    }
  }

  private repositionInTableTop(): void {
    if (this.tooltipRoot !== null && this.snapTo !== null) {
      const tooltipRect = this.tooltipRoot.nativeElement.getBoundingClientRect();
      const snapRect = this.snapTo.getBoundingClientRect();
      this.offsetTop = -this.tooltipArrowHeight / 2 - tooltipRect.height;
      this.offsetLeft = -tooltipRect.width / 2 + snapRect.width / 2;
      this.arrowOffsetLeft = tooltipRect.width / 2 - this.tooltipArrowHeight / 2;
      this.arrowOffsetTop = tooltipRect.height - (this.tooltipArrowHeight * 3) / 4;
    }
  }

  private repositionInTableRight(): void {
    if (this.tooltipRoot !== null && this.snapTo !== null) {
      const tooltipRect = this.tooltipRoot.nativeElement.getBoundingClientRect();
      const snapRect = this.snapTo.getBoundingClientRect();
      this.offsetTop = -tooltipRect.height / 2;
      this.offsetLeft = snapRect.width + this.tooltipArrowHeight / 2;
      this.arrowOffsetLeft = -this.tooltipArrowHeight / 4;
      this.arrowOffsetTop = (tooltipRect.height - this.tooltipArrowHeight) / 2;
    }
  }

  private repositionInTableBottom(): void {
    if (this.tooltipRoot !== null && this.snapTo !== null) {
      const tooltipRect = this.tooltipRoot.nativeElement.getBoundingClientRect();
      const snapRect = this.snapTo.getBoundingClientRect();
      this.offsetTop = snapRect.height + this.tooltipArrowHeight / 4;
      this.offsetLeft = -tooltipRect.width / 2 + snapRect.width / 2;
      this.arrowOffsetLeft = tooltipRect.width / 2 - this.tooltipArrowHeight / 2;
      this.arrowOffsetTop = -this.tooltipArrowHeight / 4;
    }
  }

  private repositionInLandingBottom(): void {
    if (this.tooltipRoot !== null && this.snapTo !== null) {
      const tooltipRect = this.tooltipRoot.nativeElement.getBoundingClientRect();
      const snapRect = this.snapTo.getBoundingClientRect();
      this.offsetTop = snapRect.height + tooltipRect.height / 8;
      this.offsetLeft = snapRect.width / 4;
    }
  }

  callRepositionByMode(): void {
    switch (this.positioning) {
      case 'bottom':
        this.repositionBottom();
        break;
      case 'landing-bottom':
        this.repositionInLandingBottom();
        break;
      case 'table-right':
        this.repositionInTableRight();
        break;
      case 'table-bottom':
        this.repositionInTableBottom();
        break;
      case 'table-top':
        this.repositionInTableTop();
        break;
      case 'left':
        this.repositionLeft();
        break;
      case 'right':
      default:
        this.repositionRight();
        break;
    }
  }
}
