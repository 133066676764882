import { NgFor, NgIf } from '@angular/common';
import { AfterViewInit, Component, ElementRef, OnDestroy, inject } from '@angular/core';
import { FormsModule, ReactiveFormsModule, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { Router, RouterLink } from '@angular/router';
import { Actions, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { SignUpDialogCloseReason } from 'app/shared/dialogs/enums/signup-dialog-close-reason.enum';
import { CountryTo } from 'app/shared/models/api/country.model';
import { UserRegistrationStatus } from 'app/shared/models/api/user-activation.model';
import { AppStateModel } from 'app/shared/models/auxiliary/app-state.model';
import { UserActivationStateData } from 'app/shared/reducers/user-activation.reducer';
import { GoogleTagManagerService } from 'app/shared/services/analytics/google-tag-manager.service';
import { AppConfigService } from 'app/shared/services/app-config.service';
import { IpInfoService } from 'app/shared/services/ip-info.service';
import { MessageService } from 'app/shared/services/message.service';
import { combineLatest } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';
import { loadScriptSrc } from '../../../../landing/landing-utils';
import { normalizePhoneNumber } from '../../../../platform/shared/utils';
import { phoneNumberValidator } from '../../../../platform/shared/validators/phone-number.validator';
import { loginAction, loginSuccessAction } from '../../../actions/auth.actions';
import {
  activateUserWithPasswordAction,
  activateUserWithPasswordSuccessAction,
} from '../../../actions/user-activation.actions';
import { addCloseDialogWithAnimationOnBackdropClickSubscriber, closeDialogWithAnimation } from '../../../dialogs-utils';
import { ConfigProfile } from '../../../enums/config-profile.enum';
import { selectUserActivation } from '../../../selectors/user-activation.selector';
import { CountryService } from '../../../store/countries/country-list.service';
import {
  userRegistrationAction,
  userRegistrationErrorAction,
  userRegistrationSuccessAction,
} from '../../../store/signup/user-registration.actions';
import { markAllAsTouched } from '../../../utils';
import { emailValidator } from '../../../validators/email.validator';
import { PASSWORD_MIN_LENGTH, passwordValidator } from '../../../validators/password.validator';
import { whitespaceTrimValidator } from '../../../validators/whitespace-trim.validator';
import { AbstractComponent } from '../../abstract.component';
import { InputLogoComponent } from '../../input-logo/input-logo.component';
import { LandingDialogButtonComponent } from '../../landing-button/landing-dialog-button.component';
import { CountrySelectComponent } from '../../select/country-select/country-select.component';
import { LandingDialogTitleComponent } from '../landing-dialog-title/landing-dialog-title.component';
import { LandingDialogComponent } from '../landing-dialog/landing-dialog.component';

enum RegistrationFormMode {
  NEW_REGISTRATION = 'NEW_REGISTRATION',
  USER_ACTIVATION = 'USER_ACTIVATION',
}

@Component({
  selector: 'bp-signup-dialog',
  templateUrl: './signup-dialog.component.html',
  styleUrls: ['./signup-dialog.component.scss'],
  standalone: true,
  imports: [
    LandingDialogComponent,
    NgIf,
    LandingDialogTitleComponent,
    FormsModule,
    ReactiveFormsModule,
    CountrySelectComponent,
    InputLogoComponent,
    NgFor,
    RouterLink,
    LandingDialogButtonComponent,
  ],
})
export class SignupDialogComponent extends AbstractComponent implements AfterViewInit, OnDestroy {
  countries: CountryTo[] = [];
  selectedCountry: CountryTo;
  countriesSelectDisabled = false;
  registerForm: UntypedFormGroup;
  apiErrors: string[] = [];
  registered = false;
  passReqShown = false;
  registrationRequestFinished = true;
  isPasswordVisible = false;

  mode: RegistrationFormMode = RegistrationFormMode.NEW_REGISTRATION;
  activationState: UserActivationStateData;
  isCountryVisible = true;
  turnstileToken: string | null = null;
  turnstileWidgetId: string | undefined;

  readonly emailErrorMap = new Map<string, string>([
    ['required', 'E-mail address required'],
    ['email', 'Invalid e-mail address'],
  ]);
  readonly passwordErrorMap = new Map<string, string>([
    ['required', 'Password required'],
    ['password', 'Enter a valid password'],
  ]);
  readonly passwordRequirementMap = new Map<string, string>([
    ['passLength', `at least ${PASSWORD_MIN_LENGTH} characters`],
    ['passUpper', 'contains uppercase letters'],
    ['passLower', 'contains lowercase letters'],
    ['passDigits', 'contains digits'],
  ]);
  readonly phoneNumberErrorMap = new Map<string, string>([['phoneNumber', 'Enter a valid phone number']]);

  private readonly store = inject(Store<AppStateModel>);
  private readonly actions = inject(Actions);
  private readonly countryService = inject(CountryService);
  private readonly fb = inject(UntypedFormBuilder);
  private readonly ipInfo = inject(IpInfoService);
  private readonly messageService = inject(MessageService);
  private readonly router = inject(Router);
  public readonly dialogRef = inject(MatDialogRef<SignupDialogComponent, SignUpDialogCloseReason>);
  private readonly gtmService = inject(GoogleTagManagerService);
  private readonly elementRef = inject(ElementRef);
  private readonly appConfigService = inject(AppConfigService);

  constructor() {
    super();

    addCloseDialogWithAnimationOnBackdropClickSubscriber(this.dialogRef);

    this.registerForm = this.fb.group({
      email: ['', [Validators.required, emailValidator]],
      password: ['', [Validators.required, passwordValidator]],
      phoneNumber: [null, [phoneNumberValidator, whitespaceTrimValidator]],
      newsletterAgreement: [true],
    });

    combineLatest([this.countryService.getCountries(), this.ipInfo.countryCode])
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(([allCountries, countryCodeByIp]) => {
        if (allCountries && allCountries.length > 0) {
          this.countries = allCountries;
          if (countryCodeByIp) {
            const countryByIp = this.countries.find((country) => country.alpha2 === countryCodeByIp);
            if (countryByIp) {
              this.selectedCountry = countryByIp;
            }
          }
        }
      });

    this.store
      .select(selectUserActivation)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((state) => {
        if (
          state.data != null &&
          state.data.userActivation &&
          state.data.userActivation.registrationStatus === UserRegistrationStatus.PASSWORD_NOT_SET
        ) {
          this.activationState = state.data;
          this.setActivationMode(state.data.userActivation.email);
        }
      });

    this.actions.pipe(ofType(userRegistrationSuccessAction), takeUntil(this.unsubscribe$)).subscribe(() => {
      this.apiErrors = [];
      this.countriesSelectDisabled = true;
      this.registerForm.disable();
      this.registered = true;
      this.registrationRequestFinished = true;
    });

    this.actions.pipe(ofType(userRegistrationErrorAction), takeUntil(this.unsubscribe$)).subscribe(({ errors }) => {
      this.apiErrors = errors.map((error) => {
        if (error.type === 'country') {
          return 'Country error: ' + error.message;
        }
        return error.message;
      });
      this.registrationRequestFinished = true;
    });

    this.actions.pipe(ofType(activateUserWithPasswordSuccessAction), takeUntil(this.unsubscribe$)).subscribe(() => {
      const formValues = this.registerForm.getRawValue();

      this.store.dispatch(
        loginAction({
          email: formValues.email,
          password: formValues.password,
        }),
      );

      this.messageService.success('Account was activated');
    });

    this.actions.pipe(ofType(loginSuccessAction), takeUntil(this.unsubscribe$)).subscribe(() => {
      this.router.navigateByUrl('/platform');
    });

    this.actions.pipe(ofType(userRegistrationErrorAction), takeUntil(this.unsubscribe$)).subscribe(() => {
      if (window.turnstile && this.turnstileWidgetId) {
        window.turnstile.reset(this.turnstileWidgetId);
        this.turnstileToken = null;
      }
    });
  }

  setActivationMode(email: string): void {
    this.isCountryVisible = false;
    this.countriesSelectDisabled = true;
    this.registerForm.get('email').setValue(email);
    this.registerForm.get('email').disable();

    this.mode = RegistrationFormMode.USER_ACTIVATION;
  }

  submitForm(): void {
    if (this.registerForm.invalid) {
      markAllAsTouched(this.registerForm);
      return;
    }

    if (this.mode === RegistrationFormMode.NEW_REGISTRATION) {
      this.register();
    } else if (this.mode === RegistrationFormMode.USER_ACTIVATION) {
      this.activateAccount();
    }

    if (!this.apiErrors) {
      this.closeDialog();
    }
  }

  private register(): void {
    this.patchPhoneNumberBeforeRequest();
    this.registrationRequestFinished = false;
    const affiliateId = localStorage.getItem('affiliateId');
    this.store.dispatch(
      userRegistrationAction({
        userRegistration: {
          ...this.registerForm.value,
          country: this.selectedCountry.alpha2,
          affiliateId: affiliateId,
          turnstileToken: this.turnstileToken,
        },
      }),
    );

    this.actions
      .pipe(ofType(userRegistrationSuccessAction), take(1))
      .subscribe(({ regSuccessData: { merchantId, userId } }) => {
        const phone = this.registerForm.get('phoneNumber')?.value;
        this.gtmService.trackEvent('sign_up', {
          country: this.selectedCountry?.alpha2,
          email: this.registerForm.get('email')?.value,
          merchant_id: merchantId,
          user_id: userId,
          ...(phone && { phone }),
          ...(affiliateId && { affiliate_id: affiliateId }),
          newsletter_agreement: !!this.registerForm.get('newsletterAgreement')?.value,
        });

        localStorage.removeItem('affiliateId');
      });
  }

  private activateAccount(): void {
    const password = this.registerForm.value.password;
    this.store.dispatch(activateUserWithPasswordAction({ hash: this.activationState.hash, password: password }));
    this.closeDialog();
  }

  get inputtedEmail(): boolean {
    return this.registerForm.get('email').value || '';
  }

  get signUpDisabled(): boolean {
    return !this.registrationRequestFinished;
  }

  get isCountryValid(): boolean {
    return !this.isCountryVisible || this.selectedCountry?.enabled;
  }

  closeDialog(closeReason: SignUpDialogCloseReason = SignUpDialogCloseReason.USER_CLOSE): void {
    this.passReqShown = false;
    setTimeout(() => {
      closeDialogWithAnimation(this.dialogRef, closeReason);
    }, 100);
  }

  openLoginDialog(): void {
    this.closeDialog(SignUpDialogCloseReason.LOGIN);
  }

  private patchPhoneNumberBeforeRequest(): void {
    const phoneControl = this.registerForm.get('phoneNumber');
    const insertedPhone = phoneControl?.value;

    if (insertedPhone) {
      phoneControl?.patchValue(normalizePhoneNumber(insertedPhone));
    } else phoneControl?.patchValue(null);
  }

  ngAfterViewInit(): void {
    loadScriptSrc('https://challenges.cloudflare.com/turnstile/v0/api.js', this.elementRef)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((loaded) => {
        if (loaded) {
          this.initializeTurnstile();
        } else {
          this.messageService.error('Captcha could not be loaded.');
        }
      });
  }

  ngOnDestroy() {
    super.ngOnDestroy();
    if (this.turnstileWidgetId && window.turnstile) {
      window.turnstile.remove(this.turnstileWidgetId);
    }
  }

  private initializeTurnstile(): void {
    const { profile } = this.appConfigService.config;
    const turnstileContainer = document.getElementById('turnstile-container') as HTMLElement | null;
    if (turnstileContainer && window.turnstile) {
      this.turnstileWidgetId = window.turnstile.render(turnstileContainer, {
        sitekey: profile === ConfigProfile.PROD ? '0x4AAAAAABASTqTxyzm2X-26' : '0x4AAAAAAA_zQUKzuD5JizID',
        theme: 'light',
        size: 'flexible',
        callback: (token: string) => {
          this.turnstileToken = token;
        },
      });
    }
  }
}
