import { ElementRef } from '@angular/core';
import { Observable, fromEvent, merge } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { LandingPath } from '../shared/enums/paths/landing-path.enum';

export function isCampaignPage(): boolean {
  return location.href.includes(LandingPath.Campaign);
}

export function loadScriptSrc(src: string, elementRef: ElementRef): Observable<boolean> {
  return new Observable<boolean>((observer) => {
    const script = document.createElement('script');
    script.src = src;
    script.async = true;

    const load$ = fromEvent(script, 'load').pipe(map(() => true));
    const error$ = fromEvent(script, 'error').pipe(map(() => false));

    elementRef.nativeElement.appendChild(script);

    return merge(load$, error$).pipe(take(1)).subscribe(observer);
  });
}
