import { NgClass, NgFor, NgIf, NgTemplateOutlet } from '@angular/common';
import { Component } from '@angular/core';
import { MatDialog, MatDialogRef, MatDialogState } from '@angular/material/dialog';
import { MatIcon } from '@angular/material/icon';
import { MatTooltip } from '@angular/material/tooltip';
import { Actions, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { takeUntil } from 'rxjs/operators';
import { wizardNextStepAction, wizardStepBackAction } from '../../../../shared/actions/wizard.actions';
import { AbstractComponent } from '../../../../shared/components/abstract.component';
import { ConfirmationDialogComponent } from '../../../../shared/components/dialogs/confirmation-dialog/confirmation-dialog.component';
import { CloseReason, DialogResult } from '../../../../shared/components/dialogs/dialog-result';
import { DIALOG_CONFIG_WITH_AUTOFOCUS } from '../../../../shared/dialogs-utils';
import { TooltipOnEllipsisDirective } from '../../../../shared/directives/tooltip-on-ellipsis.directive';
import { MerchantProfileVerificationStatus } from '../../../../shared/enums/merchant-profile-verification-status.enum';
import { MerchantProfileUnionModel } from '../../../../shared/models/api/merchant-profile/merchant-profile-union.model';
import { PersonModel } from '../../../../shared/models/api/merchant-profile/person-model';
import { AppStateModel } from '../../../../shared/models/auxiliary/app-state.model';
import { selectMerchantProfile } from '../../../../shared/selectors/merchant-profile.selectors';
import { MessageService } from '../../../../shared/services/message.service';
import {
  deleteMerchantDirectorAction,
  deleteMerchantDirectorSuccessAction,
  getMerchantDirectorsListAction,
} from '../../../../shared/store/merchant-directors/merchant-directors.actions';
import { selectMerchantDirectors } from '../../../../shared/store/merchant-directors/merchant-directors.selector';
import { AddOrEditMerchantDirectorDialogComponent } from '../../../common-components/aml-commons/merchant-directors-form/add-or-edit-merchant-director-dialog.component';

@Component({
  selector: 'bp-merchant-directors-list',
  templateUrl: './merchant-directors-list.component.html',
  styleUrls: ['./merchant-directors-list.component.scss', '../aml-wizard.scss'],
  standalone: true,
  imports: [MatIcon, NgClass, NgIf, NgFor, NgTemplateOutlet, MatTooltip, TooltipOnEllipsisDirective],
})
export class MerchantDirectorsListComponent extends AbstractComponent {
  public merchantDirectors: PersonModel[];
  public merchantProfile: MerchantProfileUnionModel;
  addDirectorDialogRef: MatDialogRef<AddOrEditMerchantDirectorDialogComponent>;

  constructor(
    private store: Store<AppStateModel>,
    private actions: Actions,
    protected messageService: MessageService,
    protected dialog: MatDialog,
  ) {
    super();

    this.store
      .select(selectMerchantDirectors)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((state) => {
        if (state.errors != null) {
          this.messageService.showErrors(state.errors, 'Merchant Directors Error');
          return;
        }

        if (state.data) {
          this.merchantDirectors = state.data;

          if (state.data.length === 0) {
            this.addDirector();
          }
        }
      });

    this.store
      .select(selectMerchantProfile)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((state) => {
        if (state.errors != null) {
          this.messageService.showErrors(state.errors, 'Merchant Profile Error');
          return;
        }

        this.merchantProfile = state.data;
      });

    this.actions.pipe(ofType(deleteMerchantDirectorSuccessAction), takeUntil(this.unsubscribe$)).subscribe(() => {
      this.messageService.success('Director was deleted');
    });

    this.store.dispatch(getMerchantDirectorsListAction());
  }

  editDirector(director: PersonModel): void {
    this.dialog.open(AddOrEditMerchantDirectorDialogComponent, {
      ...DIALOG_CONFIG_WITH_AUTOFOCUS,
      data: director,
    });
  }

  get canEditDirectors(): boolean {
    return this.merchantProfile?.verificationStatus === MerchantProfileVerificationStatus.BASIC_INFO_ENTERED;
  }

  deleteDirector(director: PersonModel): void {
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      ...DIALOG_CONFIG_WITH_AUTOFOCUS,
      data: {
        confirmMessage: `Do you want to delete director ${director.firstName} ${director.lastName}?`,
      },
    });

    dialogRef.afterClosed().subscribe((result: DialogResult) => {
      if (result.reason === CloseReason.SUCCESS) {
        this.store.dispatch(deleteMerchantDirectorAction({ merchantDirectorId: director.id }));
      }
    });
  }

  get isDirectorsListEmpty(): boolean {
    return this.merchantDirectors == null || this.merchantDirectors.length === 0;
  }

  public nextStep(): void {
    this.store.dispatch(wizardNextStepAction());
  }

  public stepBack(): void {
    this.store.dispatch(wizardStepBackAction());
  }

  public addDirector(): void {
    // prevent dialog opening multiple times
    if (!this.addDirectorDialogRef || this.addDirectorDialogRef.getState() !== MatDialogState.OPEN) {
      this.addDirectorDialogRef = this.dialog.open(
        AddOrEditMerchantDirectorDialogComponent,
        DIALOG_CONFIG_WITH_AUTOFOCUS,
      );
    }
  }
}
