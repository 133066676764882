import { Injectable, inject } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of as observableOf } from 'rxjs';
import { catchError, map, mergeMap } from 'rxjs/operators';
import {
  getMerchantAction,
  getMerchantErrorAction,
  getMerchantSuccessAction,
  startMerchantVerificationAdminAction,
  startMerchantVerificationAdminErrorAction,
  startMerchantVerificationAdminSuccessAction,
  updateMerchantProfileAdminAction,
  updateMerchantProfileAdminErrorAction,
  updateMerchantProfileAdminSuccessAction,
} from '../actions/merchant.actions';
import { ApiService } from '../services/api.service';

export const MERCHANT_UPDATE_PROFILE_ADMIN_ENDPOINT = `/admin/merchants/merchant-profile`;
/** @deprecated Will be replaced by Dotfile integration CBP-5349 */
export const START_MERCHANT_VERIFICATION_ADMIN_ENDPOINT = `/admin/verification/start/{merchantId}`;

@Injectable()
export class MerchantEffects {
  private readonly actions$ = inject(Actions);
  private readonly apiService = inject(ApiService);

  updateMerchant$ = createEffect(() =>
    this.actions$.pipe(
      ofType(updateMerchantProfileAdminAction),
      mergeMap(({ merchantProfile }) =>
        this.apiService.put(MERCHANT_UPDATE_PROFILE_ADMIN_ENDPOINT, merchantProfile).pipe(
          map((merchantProfile) => updateMerchantProfileAdminSuccessAction({ merchantProfile })),
          catchError((errors) => observableOf(updateMerchantProfileAdminErrorAction(errors))),
        ),
      ),
    ),
  );

  startMerchantVerificationAsAdmin$ = createEffect(() =>
    this.actions$.pipe(
      ofType(startMerchantVerificationAdminAction),
      mergeMap(({ merchantHashId, merchantProfileType }) =>
        this.apiService
          .post(START_MERCHANT_VERIFICATION_ADMIN_ENDPOINT.replace('{merchantId}', merchantHashId), {
            profileType: merchantProfileType,
          })
          .pipe(
            map(() => startMerchantVerificationAdminSuccessAction()),
            catchError((errors) => observableOf(startMerchantVerificationAdminErrorAction(errors))),
          ),
      ),
    ),
  );

  getMerchant$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getMerchantAction),
      mergeMap(() =>
        this.apiService.get(`/merchant`).pipe(
          map((merchant) => getMerchantSuccessAction({ merchant })),
          catchError((errors) => observableOf(getMerchantErrorAction(errors))),
        ),
      ),
    ),
  );
}
