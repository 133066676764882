import { NgIf } from '@angular/common';
import { Component, OnDestroy } from '@angular/core';
import { Store } from '@ngrx/store';
import { takeUntil } from 'rxjs/operators';
import { getMerchantProfileAction } from '../../../../../shared/actions/merchant-profile.actions';
import {
  getMerchantVerificationPersonAction,
  refreshStateOnIntervalAction,
  stopRefreshStateOnIntervalAction,
} from '../../../../../shared/actions/merchant-verification-idenfy.actions';
import { AbstractComponent } from '../../../../../shared/components/abstract.component';
import { IdCheckStatus } from '../../../../../shared/enums/id-check-status.enum';
import { AppStateModel } from '../../../../../shared/models/auxiliary/app-state.model';
import { selectMerchantProfile } from '../../../../../shared/selectors/merchant-profile.selectors';
import { selectIdcheckVerificationPerson } from '../../../../../shared/selectors/merchant-verification-idenfy.selector';
import { MessageService } from '../../../../../shared/services/message.service';
import { IdenfyQrCodeComponent } from '../idenfy-qr-code/idenfy-qr-code.component';
import { IdenfyStatusComponent } from '../idenfy-status/idenfy-status.component';
import { MethodSelectorComponent } from '../method-selector/method-selector.component';

@Component({
  selector: 'bp-idenfy-wrapper',
  templateUrl: './idenfy-wrapper.component.html',
  styleUrls: ['./idenfy-wrapper.component.scss'],
  standalone: true,
  imports: [NgIf, MethodSelectorComponent, IdenfyQrCodeComponent, IdenfyStatusComponent],
})
export class IdenfyWrapperComponent extends AbstractComponent implements OnDestroy {
  merchantId: string;
  nameToDisplay: string;
  methodSelected = false;
  verificationStarted = false;
  show = false;
  selectedPersonDispatched = false;

  constructor(
    private store: Store<AppStateModel>,
    private messageService: MessageService,
  ) {
    super();

    this.store
      .select(selectIdcheckVerificationPerson)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((state) => {
        if (state.errors !== null) {
          this.messageService.showErrors(state.errors, 'ID Check Person Error');
          return;
        }

        if (state.data) {
          this.nameToDisplay = state.data.firstName;
          if (state.data.idCheckStatus !== IdCheckStatus.NONE && state.data.idCheckStatus !== IdCheckStatus.ACTIVE) {
            this.verificationStarted = true;
          }

          this.show = true;
        }
      });

    this.store
      .select(selectMerchantProfile)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((state) => {
        if (state.errors !== null) {
          this.messageService.showErrors(state.errors, 'Merchant Profile Error');
          return;
        }

        if (state.data && state.data.merchantId) {
          this.merchantId = state.data.merchantId;
          if (!this.selectedPersonDispatched) {
            this.selectedPersonDispatched = true;
            this.store.dispatch(getMerchantVerificationPersonAction({ merchantId: this.merchantId }));
          }
        }
      });

    this.store.dispatch(getMerchantProfileAction());
  }

  startListeningForState(): void {
    this.methodSelected = true;
    this.store.dispatch(refreshStateOnIntervalAction({ merchantId: this.merchantId }));
  }

  backToOffer(): void {
    this.methodSelected = false;
    this.verificationStarted = false;
  }

  ngOnDestroy() {
    super.ngOnDestroy();
    this.store.dispatch(stopRefreshStateOnIntervalAction());
  }
}
