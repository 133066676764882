import { NgClass } from '@angular/common';
import { Component, Input } from '@angular/core';
import { AbstractControl, NgControl } from '@angular/forms';
import { PASSWORD_MIN_LENGTH } from '../../validators/password.validator';
import { AbstractComponent } from '../abstract.component';

@Component({
  selector: 'bp-password-requirements',
  templateUrl: './password-requirements.component.html',
  styleUrls: ['./password-requirements.component.scss'],
  standalone: true,
  imports: [NgClass],
})
export class PasswordRequirementsComponent extends AbstractComponent {
  @Input() passwordControl: AbstractControl | NgControl;
  @Input() isLanding: boolean = false;
  readonly passwordMinLength = PASSWORD_MIN_LENGTH;

  constructor() {
    super();
  }

  get passwordErrors(): { [errorKey: string]: boolean } {
    return this.passwordControl.errors;
  }
}
