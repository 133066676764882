<button
  [type]="type"
  (click)="type === 'button' ? clicked.emit() : null"
  class="landing-btn landing-btn--lg"
  [disabled]="disabled"
>
  <span
    class="landing-btn__text in-dialog"
    [ngStyle]="isPublicInvoiceDialog && { 'min-height': 'calc(var(--scale) * 60px)' }"
    ><ng-content></ng-content
  ></span>
</button>
