import { Injectable, inject } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, mergeMap, tap } from 'rxjs/operators';
import { ApiService } from '../../../services/api.service';
import { MessageService } from '../../../services/message.service';
import {
  getCryptoPaymentMethodsAdminAction,
  getCryptoPaymentMethodsAdminActionError,
  getCryptoPaymentMethodsAdminActionSuccess,
  updateCryptoPaymentMethodAdminAction,
  updateCryptoPaymentMethodAdminActionError,
  updateCryptoPaymentMethodAdminActionSuccess,
} from './crypto-payment-methods-admin.actions';

export const PAYMENT_METHODS_ADMIN_ENDPOINT = '/admin/payment-methods';

@Injectable()
export class CryptoPaymentMethodsAdminEffect {
  private readonly actions$ = inject(Actions);
  private readonly api = inject(ApiService);
  private readonly messageService = inject(MessageService);

  get$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getCryptoPaymentMethodsAdminAction),
      mergeMap(() =>
        this.api.get(this.url()).pipe(
          map((paymentMethods) => getCryptoPaymentMethodsAdminActionSuccess({ paymentMethods })),
          catchError((errors) => of(getCryptoPaymentMethodsAdminActionError(errors))),
        ),
      ),
    ),
  );

  update$ = createEffect(() =>
    this.actions$.pipe(
      ofType(updateCryptoPaymentMethodAdminAction),
      mergeMap(({ paymentMethod, paymentMethodId }) =>
        this.api.put(this.urlUpdate(paymentMethodId), paymentMethod).pipe(
          map(() => updateCryptoPaymentMethodAdminActionSuccess()),
          catchError((errors) => of(updateCryptoPaymentMethodAdminActionError(errors))),
        ),
      ),
    ),
  );

  url(): string {
    return PAYMENT_METHODS_ADMIN_ENDPOINT;
  }

  urlUpdate(paymentMethodId: string): string {
    return `${PAYMENT_METHODS_ADMIN_ENDPOINT}/${paymentMethodId}`;
  }

  showSuccessMessageOnPaymentMethodUpdate$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(updateCryptoPaymentMethodAdminActionSuccess),
        tap(() => {
          this.messageService.success(`Payment method settings have been successfully updated!`);
        }),
      ),
    { dispatch: false },
  );

  showErrorMessageOnPaymentMethodUpdate$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(updateCryptoPaymentMethodAdminActionError),
        tap(({ errors }) => {
          this.messageService.showErrors(errors, 'Payment method settings could not be updated.');
        }),
      ),
    { dispatch: false },
  );
}
