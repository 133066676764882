import { Injectable, OnDestroy, Signal, inject, signal } from '@angular/core';
import { Store } from '@ngrx/store';
import { Subject } from 'rxjs';
import { distinctUntilChanged, filter, takeUntil } from 'rxjs/operators';
import { MerchantStatus } from '../../../shared/models/api/merchant-status.enum';
import { AppStateModel } from '../../../shared/models/auxiliary/app-state.model';
import { selectMerchantStatus } from '../../../shared/selectors/merchant.selector';
import { OnboardingView } from './onboarding-view.model';

/**
 * Handles switching Onboarding state between
 * a) Onboarding shown (state != null)
 *   - Getting started (BASE_VIEW)
 *   - Merchant type selection (MERCHANT_TYPE_SELECTION_VIEW)
 *   - Basic info forms (INDIVIDUAL_FORM_VIEW/COMPANY_FORM_VIEW)
 * b) Onboarding hidden (state == null)
 */
@Injectable({
  providedIn: 'root',
})
export class OnboardingViewService implements OnDestroy {
  private readonly unsubscribe$ = new Subject<void>();
  private readonly store = inject(Store<AppStateModel>);
  private readonly viewState = signal<OnboardingView | null>(null);

  constructor() {
    this.store
      .select(selectMerchantStatus)
      .pipe(
        filter((status) => status != null),
        distinctUntilChanged(),
        takeUntil(this.unsubscribe$),
      )
      .subscribe((status) => {
        if (status === MerchantStatus.NEW || status === MerchantStatus.TESTING) {
          this.viewState.set(OnboardingView.BASE_VIEW);
        } else {
          this.viewState.set(null);
        }
      });
  }

  setCurrentOnboardingView(view: OnboardingView): void {
    this.viewState.set(view);
  }

  getCurrentOnboardingView(): Signal<OnboardingView | null> {
    return this.viewState;
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
