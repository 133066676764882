import { NgFor, NgIf } from '@angular/common';
import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';
import {
  AbstractControl,
  FormsModule,
  ReactiveFormsModule,
  UntypedFormArray,
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { TwoFaType } from '../../models/api/auth.model';
import { AbstractComponent } from '../abstract.component';

const EMIT_TIMEOUT = 300;

@Component({
  selector: 'bp-two-factor-auth-for-login',
  templateUrl: './two-factor-auth-for-login.component.html',
  styleUrls: ['./two-factor-auth-for-login.component.scss'],
  standalone: true,
  imports: [FormsModule, ReactiveFormsModule, NgIf, NgFor],
})
export class TwoFactorAuthForLoginComponent extends AbstractComponent implements OnInit, OnChanges {
  @Input() errorMessage: string;
  @Input() errorShown: boolean;
  @Input() twoFaType: TwoFaType;
  @Input() isInputDisabled = false;
  @Output() fill = new EventEmitter();

  readonly inputIdPrefix = 'code-input-';
  readonly maxInputLength = 1;
  readonly codeLengths = new Map<string, number>([
    [TwoFaType.GOOGLE_2FA, 6],
    [TwoFaType.EMAIL_2FA, 5],
  ]);
  readonly patterns = new Map<TwoFaType, { character: RegExp; string: RegExp }>([
    [TwoFaType.GOOGLE_2FA, { character: new RegExp('^[0-9]$'), string: new RegExp('^[0-9]+$') }],
    [TwoFaType.EMAIL_2FA, { character: new RegExp('^[a-zA-Z0-9]$'), string: new RegExp('^[a-zA-Z0-9]+$') }],
  ]);
  twoFAForm: UntypedFormGroup;
  code: UntypedFormArray = new UntypedFormArray([]);

  constructor(private fb: UntypedFormBuilder) {
    super();

    this.twoFAForm = this.fb.group({
      code: this.code,
    });
  }

  ngOnInit() {
    const symbolsCount = this.codeLength;
    for (let i = 0; i < symbolsCount; i++) {
      this.code.push(new UntypedFormControl('', [Validators.required]));
    }
    setTimeout(() => document.getElementById(this.inputIdPrefix + 0).focus(), 100);
  }

  ngOnChanges() {
    // disabled attribute stopped working on inputs after Angular upgrade (CBP-4411),
    // so when the parent component changes this.isInputDisabled, we need to enable/disable controls manually
    this.isInputDisabled ? this.disableInputs() : this.enableInputs();
  }

  onKeyDown(event: KeyboardEvent, inputNumber: number): void | boolean {
    const value = event.key;
    const isCtrlOrMetaKey = event.ctrlKey || event.metaKey;

    // ignores paste events
    if (isCtrlOrMetaKey) {
      return;
    }

    // disables the inserting of a typed value excepting tabulator and backspace
    if (value !== 'Tab' && value !== 'Backspace') {
      event.preventDefault();
    }

    // moves focus to the previous input, if backspace was pressed inside an empty input
    if (value === 'Backspace' && inputNumber > 0 && this.code.at(inputNumber).value.length === 0) {
      setTimeout(() => this.focusInputByIndex(inputNumber - 1), 0);
      return;
    }

    // disables inserting, when an unsuitable value was typed
    if (!this.testIfCharacterMatchesPattern(value)) {
      return;
    }

    // inserts the value and shifts focus to the next input
    if (value.length === this.maxInputLength && inputNumber < this.codeLength - 1) {
      this.code.at(inputNumber).setValue(value, { emitEvent: false });
      setTimeout(() => this.focusInputByIndex(inputNumber + 1), 0);
      return;
    }

    if (value !== 'Tab') {
      this.code.at(inputNumber).setValue(value, { emitEvent: false });
      return;
    }

    // maxlength attribute doesn't affect inputs with a type attribute set to 'number',
    // so this part is needed to prevent inserting more than one symbol in the last input
    if (this.code.at(inputNumber).value.length === this.maxInputLength && inputNumber === this.codeLength - 1) {
      return false;
    }
  }

  onPaste(event: ClipboardEvent, inputNumber: number): void {
    // prevents default pasting
    event.preventDefault();

    const value = event.clipboardData.getData('Text');
    this.pasteValues(value, inputNumber);

    //Solves the issue when code copied from the context menu is not dispatched automatically.
    if (!this.isInputDisabled) {
      this.sendTwoFaCode();
    }
  }

  /**
   * On Android, **paste event** is not triggered when the clipboard button from the keyboard menu is used,
   * so we should process **input event**.
   * See https://stackoverflow.com/questions/70452313/google-keyboard-clipboard-does-not-trigger-a-paste-event
   */
  onInput(event: Event, inputNumber: number) {
    const data = (event as InputEvent).data;
    this.pasteValues(data, inputNumber);
  }

  pasteValues(value: string, inputNumber: number): void {
    // disables inserting, when an unsuitable value was typed
    if (!this.testIfStringMatchesPattern(value)) {
      return;
    }

    // inserts a part of a pasted value which fits the length of inputs between the focused input and the last input
    const valuesToInsert =
      value.length > this.codeLength - inputNumber ? value.substring(0, this.codeLength - inputNumber) : value;
    for (let i = inputNumber; i < this.codeLength; i++) {
      this.code.at(i).setValue(valuesToInsert.at(i - inputNumber), { emitEvent: false });
    }

    const inputNextAfterInsertedValue = inputNumber + value.length;
    if (inputNextAfterInsertedValue <= this.codeLength - 1) {
      this.focusInputByIndex(inputNextAfterInsertedValue);
    }
  }

  onKeyUp(): void {
    this.sendTwoFaCode();
  }

  sendTwoFaCode(): void {
    const twoFaCode = this.twoFAForm.get('code').value.join('');
    if (twoFaCode.length === this.codeLength) {
      this.disableInputs();
      setTimeout(() => this.fill.emit(twoFaCode), EMIT_TIMEOUT);
    }
  }

  get isGoogle2fa(): boolean {
    return this.twoFaType === TwoFaType.GOOGLE_2FA;
  }

  get codeLength(): number {
    return this.codeLengths.get(this.twoFaType);
  }

  get controlType(): 'text' | 'number' {
    return this.twoFaType === TwoFaType.GOOGLE_2FA ? 'number' : 'text';
  }

  convertAbstractToFormControl(control: AbstractControl): UntypedFormControl {
    return control as UntypedFormControl;
  }

  enableInputs(): void {
    this.code.controls.forEach((control) => control.enable({ emitEvent: false }));
  }

  disableInputs(): void {
    this.code.controls.forEach((control) => control.disable({ emitEvent: false }));
  }

  focusInputByIndex(inputIndex: number): void {
    document.getElementById(this.inputIdPrefix + inputIndex).focus();
  }

  testIfCharacterMatchesPattern(value): boolean {
    return this.patterns.get(this.twoFaType).character.test(value);
  }

  testIfStringMatchesPattern(value): boolean {
    return this.patterns.get(this.twoFaType).string.test(value);
  }
}
