import { Directive, ElementRef, EventEmitter, HostListener, Output } from '@angular/core';

@Directive({
  selector: '[bpOuterClick]',
  standalone: true,
})
export class OuterClickDirective {
  @Output()
  outerClick: EventEmitter<void> = new EventEmitter();

  constructor(private target: ElementRef) {}

  @HostListener('document:mousedown', ['$event'])
  onGlobalClick(event: MouseEvent): void {
    if (!this.target.nativeElement.contains(event.target)) {
      this.outerClick.emit();
    }
  }
}
