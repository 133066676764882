import { NgClass, NgTemplateOutlet } from '@angular/common';
import { Component, EventEmitter, Input, Output, TemplateRef } from '@angular/core';
import { GenericDialogComponent } from '../generic-dialog/generic-dialog.component';

@Component({
  selector: 'bp-header-dialog',
  templateUrl: './header-dialog.component.html',
  styleUrls: ['./header-dialog.component.scss'],
  standalone: true,
  imports: [GenericDialogComponent, NgClass, NgTemplateOutlet],
})
export class HeaderDialogComponent {
  @Output()
  closePressed = new EventEmitter<void>();

  @Input()
  dialogTitle: string;

  @Input()
  isPlatformDialog = false;

  @Input()
  headerButton: TemplateRef<any>;
}
