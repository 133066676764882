import { Injectable, inject } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { exhaustMap, of as observableOf } from 'rxjs';
import { catchError, map, switchMap, tap } from 'rxjs/operators';
import {
  invalidTokenUsedAction,
  loginAction,
  loginErrorAction,
  loginSuccessAction,
  loginTwoFANeededAction,
  logoutAction,
  logoutErrorAction,
  logoutSuccessAction,
  twoFactorAuthAction,
  twoFactorAuthErrorAction,
} from '../actions/auth.actions';
import { appActions } from '../constants';
import { LandingPath } from '../enums/paths/landing-path.enum';
import { ApiService } from '../services/api.service';
import { DialogService } from '../services/dialog.service';
import { MessageService } from '../services/message.service';

const USERS_LOGOUT_ENDPOINT = '/oauth/logout';

@Injectable()
export class AuthEffect {
  private readonly actions$ = inject(Actions);
  private readonly api = inject(ApiService);
  private readonly dialog = inject(DialogService);
  private readonly router = inject(Router);
  private readonly messageService = inject(MessageService);

  login$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loginAction),
      switchMap(({ email, password }) =>
        this.api.post_token(email, password).pipe(
          map((response) => loginSuccessAction({ token: response.tokenValue })),
          catchError((response: any) => {
            if (this.api.isInvalid2faErrorResponse(response)) {
              return observableOf(
                loginTwoFANeededAction({
                  username: email,
                  password: password,
                  twoFaRequiredType: this.api.get2faTypeFromErrorCode(response),
                }),
              );
            }
            return observableOf(loginErrorAction(response));
          }),
        ),
      ),
    ),
  );

  clear$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loginSuccessAction),
      map(() => ({ type: appActions.CLEAR })),
    ),
  );

  twoFactorAuthenticate$ = createEffect(() =>
    this.actions$.pipe(
      ofType(twoFactorAuthAction),
      switchMap(({ email, password, otp }) =>
        this.api.post_token(email, password, otp).pipe(
          map((response) => loginSuccessAction({ token: response.tokenValue })),
          catchError((response: any) => observableOf(twoFactorAuthErrorAction(response))),
        ),
      ),
    ),
  );

  logout$ = createEffect(() =>
    this.actions$.pipe(
      ofType(logoutAction),
      switchMap(() =>
        this.api.post(USERS_LOGOUT_ENDPOINT).pipe(
          map(() => logoutSuccessAction()),
          catchError((errors) => observableOf(logoutErrorAction(errors))),
        ),
      ),
    ),
  );

  invalidTokenUsed$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(invalidTokenUsedAction),
        tap(),
        exhaustMap(() => {
          this.messageService.info('Your login has expired');
          const dialogRef = this.dialog.openLogin();
          return dialogRef.afterClosed();
        }),
      ),
    { dispatch: false },
  );

  redirectAfterLogoutSuccessAction$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(logoutSuccessAction),
        tap(() => {
          this.router.navigateByUrl(LandingPath.Home);
        }),
      ),
    { dispatch: false },
  );
}
