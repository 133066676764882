import { Injectable } from '@angular/core';
import { Actions, ofType } from '@ngrx/effects';
import { Store, select } from '@ngrx/store';
import { Observable, combineLatest } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { PublicProfileModel } from '../../models/api/public-profile.model';
import { AppStateModel } from '../../models/auxiliary/app-state.model';
import { selectUser } from '../../selectors/user.selector';
import { MessageService } from '../../services/message.service';
import {
  PublicProfileActionTypes,
  getPublicProfileAction,
  getPublicProfileByMerchantIdAction,
} from './public-profile.actions';
import { selectPublicProfile, selectPublicProfileLoading } from './public-profile.selector';

@Injectable({
  providedIn: 'root',
})
export class PublicProfileService {
  constructor(
    private messageService: MessageService,
    private store: Store<AppStateModel>,
    private actions: Actions,
  ) {
    combineLatest([this.store.pipe(select(selectPublicProfile)), store.pipe(select(selectUser))]).subscribe(
      ([state, user]) => {
        if (state.initial && user.data?.merchantId) this.refresh({});
      },
    );
    this.store
      .pipe(
        select(selectPublicProfile),
        filter((state) => state.errors !== null),
        map((state) => state.errors),
      )
      .subscribe((errors) => {
        this.messageService.showErrors(errors, 'Public Profile Error');
      });
    this.actions
      .pipe(ofType(PublicProfileActionTypes.UpdatePublicProfileSuccess))
      .subscribe(() => this.messageService.success('Public profile was successfully updated'));
  }

  public getPublicProfile(): Observable<PublicProfileModel> {
    return this.store.pipe(
      select(selectPublicProfile),
      filter((state) => state != null && state.data != null && !state.initial),
      map((state) => state.data),
    );
  }

  public getPublicProfileLoading(): Observable<boolean> {
    return this.store.select(selectPublicProfileLoading).pipe(filter((loading) => loading != null));
  }

  public refresh(params: { merchantId?: string }): void {
    params.merchantId
      ? this.store.dispatch(getPublicProfileByMerchantIdAction({ merchantId: params.merchantId }))
      : this.store.dispatch(getPublicProfileAction());
  }
}
