import { NgStyle } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'bp-landing-dialog-button',
  templateUrl: './landing-dialog-button.component.html',
  styleUrls: ['./landing-dialog-button.component.scss'],
  standalone: true,
  imports: [NgStyle],
})
export class LandingDialogButtonComponent {
  @Input() type: 'submit' | 'button' = 'button';
  @Input() disabled = false;
  @Input() isPublicInvoiceDialog?: boolean;
  @Output() clicked: EventEmitter<any> = new EventEmitter<any>();
}
