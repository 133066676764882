import { Injectable, inject } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import moment from 'moment';
import { exhaustMap, of, timer } from 'rxjs';
import { catchError, map, mergeMap, switchMap, takeUntil, tap } from 'rxjs/operators';
import { OnboardingView } from '../../platform/merchant/onboarding/onboarding-view.model';
import { OnboardingViewService } from '../../platform/merchant/onboarding/onboarding-view.service';
import { logoutAction } from '../actions/auth.actions';
import {
  createDotfileCaseAsAdminAction,
  createDotfileCaseAsAdminErrorAction,
  createDotfileCaseAsAdminSuccessAction,
  getDotfileCaseAsMerchantAction,
  getDotfileCaseAsMerchantErrorAction,
  getDotfileCaseAsMerchantSuccessAction,
  getDotfileClientPortalLinkAction,
  getDotfileClientPortalLinkErrorAction,
  getDotfileClientPortalLinkSuccessAction,
  getMerchantDotfileCaseAsAdminAction,
  getMerchantDotfileCaseAsAdminErrorAction,
  getMerchantDotfileCaseAsAdminSuccessAction,
  getVerificationBasicInfoAsMerchantAction,
  getVerificationBasicInfoAsMerchantErrorAction,
  getVerificationBasicInfoAsMerchantSuccessAction,
  postVerificationBasicInfoAsMerchantAction,
  postVerificationBasicInfoAsMerchantErrorAction,
  postVerificationBasicInfoAsMerchantSuccessAction,
  scheduleDotfileClientPortalLinkRefreshAction,
} from '../actions/merchant-verification.actions';
import { getMerchantAction } from '../actions/merchant.actions';
import { AppStateModel } from '../models/auxiliary/app-state.model';
import { ApiService } from '../services/api.service';
import { MessageService } from '../services/message.service';
import { PublicProfileService } from '../store/public-profile/public-profile.service';

const MERCHANT_DOTFILE_CLIENT_PORTAL_LINK_EP = '/verification/dotfile-client-portal-link';
const MERCHANT_DOTFILE_CASE_EP = '/verification/dotfile-case';
const MERCHANT_BASIC_INFO_EP = '/verification/basic-info';
const MERCHANT_BASIC_INFO_COMPANY_EP = `${MERCHANT_BASIC_INFO_EP}/company`;
const MERCHANT_BASIC_INFO_INDIVIDUAL_EP = `${MERCHANT_BASIC_INFO_EP}/individual`;

const DOTFILE_LINK_EXPIRY_MINUTES = 15;

@Injectable()
export class MerchantVerificationEffect {
  private readonly actions$ = inject(Actions);
  private readonly api = inject(ApiService);
  private readonly messageService = inject(MessageService);
  private readonly onboardingService = inject(OnboardingViewService);
  private readonly publicProfileService = inject(PublicProfileService);
  private readonly store = inject(Store<AppStateModel>);

  getMerchantDotfileCaseAsAdmin$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getMerchantDotfileCaseAsAdminAction),
      mergeMap(({ merchantId }) =>
        this.api.get(`/admin/merchants/${merchantId}/verification/dotfile-case`).pipe(
          map((dotfileCase) => getMerchantDotfileCaseAsAdminSuccessAction({ dotfileCase })),
          catchError(({ errors }) => {
            this.messageService.showErrors(errors, 'Failed to retrieve the Dotfile case for the current merchant');
            return of(getMerchantDotfileCaseAsAdminErrorAction(errors));
          }),
        ),
      ),
    ),
  );

  createDotfileCaseAsAdmin$ = createEffect(() =>
    this.actions$.pipe(
      ofType(createDotfileCaseAsAdminAction),
      mergeMap(({ merchantId }) =>
        this.api.post(`/admin/merchants/${merchantId}/verification/dotfile-case`).pipe(
          map(() => createDotfileCaseAsAdminSuccessAction()),
          catchError(({ errors }) => {
            this.messageService.showErrors(errors, 'Failed to create Dotfile case for the current merchant');
            return of(createDotfileCaseAsAdminErrorAction(errors));
          }),
        ),
      ),
    ),
  );

  getDotfileCaseAsMerchant$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getDotfileCaseAsMerchantAction),
      exhaustMap(() =>
        this.api.get(MERCHANT_DOTFILE_CASE_EP).pipe(
          map((dotfileCase) => getDotfileCaseAsMerchantSuccessAction({ dotfileCase })),
          catchError(({ errors }) => of(getDotfileCaseAsMerchantErrorAction(errors))),
        ),
      ),
    ),
  );

  getVerificationBasicInfo$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getVerificationBasicInfoAsMerchantAction),
      switchMap(() =>
        this.api.get(MERCHANT_BASIC_INFO_EP).pipe(
          map((basicInfo) => getVerificationBasicInfoAsMerchantSuccessAction({ basicInfo })),
          catchError(({ errors }) => of(getVerificationBasicInfoAsMerchantErrorAction(errors))),
        ),
      ),
    ),
  );

  postVerificationBasicInfo$ = createEffect(() =>
    this.actions$.pipe(
      ofType(postVerificationBasicInfoAsMerchantAction),
      exhaustMap(({ payload }) => {
        const { type, ...basicInfo } = payload;
        const endpoint = type === 'company' ? MERCHANT_BASIC_INFO_COMPANY_EP : MERCHANT_BASIC_INFO_INDIVIDUAL_EP;
        return this.api.post(endpoint, basicInfo).pipe(
          tap(() => {
            this.store.dispatch(getMerchantAction());
            this.publicProfileService.refresh({});
            this.store.dispatch(getDotfileCaseAsMerchantAction());
            this.store.dispatch(getDotfileClientPortalLinkAction());
            this.onboardingService.setCurrentOnboardingView(OnboardingView.BASE_VIEW);
          }),
          map(() =>
            postVerificationBasicInfoAsMerchantSuccessAction({
              basicInfo: payload,
            }),
          ),
          catchError(({ errors }) => {
            this.messageService.showErrors(errors, 'Failed to send your information');
            return of(postVerificationBasicInfoAsMerchantErrorAction(errors));
          }),
        );
      }),
    ),
  );

  getDotfileClientPortalLink$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getDotfileClientPortalLinkAction),
      exhaustMap(() =>
        this.api.getAsText(MERCHANT_DOTFILE_CLIENT_PORTAL_LINK_EP).pipe(
          map((link?: string | null) => {
            const linkPayload =
              link != null
                ? { link, expires: moment().add(DOTFILE_LINK_EXPIRY_MINUTES, 'minutes').toISOString() }
                : null;
            return getDotfileClientPortalLinkSuccessAction(linkPayload);
          }),
          tap((action) => {
            if (action.link) {
              const expirationTime = moment(action.expires).diff(moment(), 'milliseconds');
              this.store.dispatch(scheduleDotfileClientPortalLinkRefreshAction({ expirationTime }));
            }
          }),
          catchError(({ errors }) => {
            this.messageService.showErrors(errors, 'Failed to retrieve verification portal link');
            return of(getDotfileClientPortalLinkErrorAction(errors));
          }),
        ),
      ),
    ),
  );

  scheduleDotfileClientPortalLinkReFetch$ = createEffect(() =>
    this.actions$.pipe(
      ofType(scheduleDotfileClientPortalLinkRefreshAction),
      switchMap(({ expirationTime }) =>
        timer(expirationTime - 1000).pipe(
          map(() => getDotfileClientPortalLinkAction()),
          takeUntil(this.actions$.pipe(ofType(logoutAction))),
        ),
      ),
    ),
  );
}
